.shop-checkout-cart-item{
    align-items: center;
    display: flex;
    margin-bottom: 12px;
}
.shop-checkout-cart-item-breakdown{
    display: flex;
    flex: 1 1;
    flex-direction: column;
    margin: 0 10px;
}
.shop-checkout-cart-item-title{
    font-size: 14px;
}
.shop-checkout-cart-item-price-s{
    font-size: 12px;
}
.shop-checkout-cart-item-price{

}
.shop-checkout-summary-wrapper{
    display: flex;
    justify-content: space-between;
}
.shop-checkout-summary-wrapper .checkout-total{
    font-size: 19px;
}
.offline-shop-btn-mode{
    border-radius: 5px;
    font-weight: bold;
    background-color: #fff;
    color: #000;
    padding: 7px;
}
.offline-shop-btn-mode.active{
    background-color: #FF2D47;
    color: #fff;
}
.offline-shop-btn-mode-wrapper{
    display: flex;
    gap: 20px;
}