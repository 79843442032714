@import url("https://fonts.googleapis.com/css?family=Open+Sans:400,600&display=swap");

/* Style the accordion section */
.accordion__section {
  display: flex;
  width: 100%;
  flex-direction: column;
}

/* Style the buttons that are used to open and close the accordion panel */
.accordion {
  background-color: #eee;
  color: #444;
  cursor: pointer;
  padding: 18px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: none;
  outline: none;
  transition: background-color 0.6s ease;
  flex-wrap: wrap;
}

/* Add a background color to the button if it is clicked on (add the .active class with JS), and when you move the mouse over it (hover) */
.accordion:hover,
.active {
  background-color: #ccc;
}

.accordion__date {
  flex: 1;
}

.accordion__ref {
  flex: 1;
  color: red;
}

/* Style the accordion content title */
.accordion__title {
  flex: 2;
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  font-size: 14px;
}

.accordion_title {
  font-family: "Open Sans", sans-serif;
  font-size: 13.5px;
}

.accordion__status {
  flex: 2;
  /* width: 100px; */
  /* float: left; */
  /* text-align: left; */
}

.accordion__green, .accordion__red, .accordion__yellow {
  flex: 1;
  font-size: 25px !important;
}

.accordion__green {
  color: #6DCE49;
}

.accordion__red {
  color: #CC0909;
}

.accordion__yellow {
  color: #FCBD17;
}

.accordion__title_disabled {
  color: lightgray;
}

/* Style the accordion chevron icon */
.accordion__icon {
  transition: transform 0.6s ease;
}

/* Style to rotate icon when state is active */
.rotate {
  transform: rotate(90deg);
}

/* Style the accordion content panel. Note: hidden by default */
.accordion__content {
  background-color: white;
  overflow: hidden;
  transition: max-height 0.6s ease;
}

/* Style the accordion content text */
.accordion__text {
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  font-size: 14px;
  padding: 18px;
}
