.basma-sidebar-chat {
    width: 40%;
    position: fixed;
    right: 0;
    top: 0;
    border: 1px solid #e5e0e0;
    height: 90%;
    margin-top: 5.6rem;
    border-radius: 5px;
    box-shadow: rgba(0, 0, 0, 0.02) -25px 1px 25px 0px, rgba(0, 0, 0, 0.25) 2px 0px 11px 0px;
    transition: all 0.2s ease-out 0.2s;
    transform: translateX(100%);
    z-index: 1000;
    background-color: white;
}

.basma-sidebar-chat.expanded {
    transform: translateX(0px) !important;
}
#chat-loader{
    position: fixed;
	width: 100%;
	height: 100vh;
	z-index: 999;
}
.basma-chat-button{
    position: fixed;
    bottom: 10%;
    right: 2%;
    z-index: 1000;
    cursor: pointer;
    transform: translateX(0px);
    transition: all 0.2s ease-out 0.2s;
}
.basma-chat-button.expanded{
    transform: translateX(200%) !important;
} 
.basma-chat-button svg{
    color: #ff2c46;
    width: 3em;
    height: 3em;
}
.basma-chat-lightbox .lb-container .lb-header div.next,.basma-chat-lightbox .lb-container .lb-header div.prev{
    display: none
}

.basma-chat-lightbox .lb-container .lb-header {
    /* justify-content: space-around !important */
}


/* .basma-chat-button svg::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: black;
    z-index: -1;
    -webkit-background-clip: text;
    background-clip: text;
  } */

@media (min-width:1100px) {
    .basma-sidebar-chat {
        width: 30%;
    }
}

