.pdf-style {
  font-family: Montserrat !important;
  line-height: 1.23;
  margin-right: 10px;
  height: 1048px;
  max-height: 1048px;
}

.centered-heading {
  text-align: center;
  margin-top: 0px;

  display: block;
  font-size: 2em;
  margin-block-start: 0.67em;
  margin-block-end: 0.67em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-weight: bold;

}

.patient-question {
  font-size: 17px;
  padding-bottom: 5px;
  margin-top: 10px;
  display: flex;
  flex-direction: row
}
.arabic-text-ipr{
  font-family: "Symbio Medium", sans-serif;
  direction: rtl;
}
.patient-data {
  
  margin-left: 5px !important;
  color: black !important;
 
}
.row-name .patient-data span {
  font-weight: bold;
}

.row-name {
  font-size: 24px;
}

.comment-border {
  min-height: 288px !important;
  border: 1px solid red;
  padding: 5px 10px;
  /* font-family: 'Times New Roman', serif; */
}

.patient-comment {
  color: black !important;
  font-size: 11px !important;
}


.footer-container {
  display: flex;
  flex-direction: row;
  gap: 45px;
  font-size: 10px;
}

.aligner-question {
  font-size: 22px;
  padding-bottom: 5px;
  margin-top: 10px;

}

.aligner-data {
  padding: 1%;
  font-size: 22px;
  color: red;
  margin-left: 5px;
  text-align: "center";
  border: 1px solid black
}

.red-square-upper {
  background-color: #ff2c46;
  width: 8px;
  height: 8px;
  display: block;
  position: absolute;
  z-Index: 1;
  top: 48%;
  left: 22%;
  margin-left: 5px;
  border-radius: 2px;
  border: 1px solid white;

}

.red-square-buttom {
  background-color: #ff2c46;
  width: 8px;
  height: 8px;
  display: block;
  position: absolute;
  z-Index: 1;
  top: 44%;
  left: 22%;
  margin-left: 5px;
  border-radius: 2px;
  border: 1px solid white;

}

.vl {
  border: 1px dashed black !important;
  height: auto;
  position: absolute;
  top: 8%;
  left: 50%;
  bottom: 9%;
}

.teeth-image {
  height: 65px;
  z-index: 2;
}

.between-teeth {
  display: flex;
  flex-direction: column;
  width: 10px;
  margin: auto;
  align-items: center
}

.inside-box-number {
  font-size: small;
  font-weight: bold;
  width: 30px;
}

.footer-important {
  margin: 1.6% 0%;
}

.row-flexing {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.attatchment-img {
  display: flex;
  height: 130px;
  flex-grow: 1
}

.column-flexing {
  display: flex;
  flex-direction: column;
}

.notes {
  margin: 0;
  font-size: 13px;
}

.tooth-checkbox-container-pdf {
  display: flex;
  align-items: center;
  margin: 3.5% 0%;
  flex-direction: column;
  row-gap: 0px;
  position: relative
}

.single-tooth-pdf {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
}

.teeth-upper-pdf,
.teeth-bottom-pdf {
  display: flex;
  width: 90%;
  margin: auto;
}