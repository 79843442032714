.allPdf {
  max-height: 59.2cm;
}
.container {
  width: 21cm;
  min-height: 29.6cm;
  max-height: 29.6cm;
  background-color: #ffffff;
  position: relative;
  display: flex;
  flex-direction: column;
  margin-top: 0.1cm;
  /* margin: 0 auto; */
}

.first-container {
  margin-top: 54px;
  display: flex;
  flex-direction: column;
  /* margin: 0 auto; */
  /* margin-left: 53px; */
}
.first-containerAr {
  margin-top: 54px;
  display: flex;
  flex-direction: column;
  direction: rtl;

  /* flex-wrap: wrap;
    align-content: right; */
  /* margin: 0 auto; */
  /* margin-left: 53px; */
}
@font-face {
  font-family: "Montserrat Black";
  src: url(../fonts/Montserrat-Black.ttf);
}
@font-face {
  font-family: "Montserrat Bold";
  src: url(../fonts/Montserrat-Bold.ttf);
}
@font-face {
  font-family: "Montserrat Medium";
  src: url(../fonts/Montserrat-Medium.ttf);
}

.first-header {
  font-family: "Montserrat Black";
  font-weight: 900;
  font-size: 29px;
  color: #484442;
  display: inline-block;
}
.line {
  border: 2px solid #ff2d47;
  border-radius: 3px;
  width: 92px;
  margin-top: 15px;
  margin-bottom: 14px;
}
.text {
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  font-size: 21px;
  color: #484442;
  display: inline-block;
}
.your-smile {
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  font-size: 21px;
  display: inline-block;
}
.scd-container {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  /* margin: 0 auto; */
  /* margin-left: 53px; */
}
.scd-header {
  font-family: "Montserrat Bold";
  font-weight: 700;
  font-size: 26px;
  color: #484442;
  display: inline-block;
}
.scd-line {
  border: 2px solid #ff2d47;
  border-radius: 3px;
  width: 42px;
  margin-top: 12px;
  margin-bottom: 11px;
  display: inline-block;
}
.scd-text {
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  font-size: 13px;
  max-width: 18cm;
  overflow-wrap: break-word;
  padding-left: 15px;
  padding-right: 15px;

  /* display: inline-block;
    word-break: break-word; */
  /* max-width: 70%; */
}
.red-text {
  color: red;
  font-size: 15px;
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
}
.image {
  /* margin-top: 42px;
  margin-bottom: 37px; */
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}
.third-container {
  /* margin-left: 53px; */
  /* min-width: 1000px; */
  display: flex;
  flex-direction: column;
  margin-top: 20px;
}
.third-header {
  font-family: "Montserrat Bold";
  font-weight: 700;
  font-size: 26px;
  color: #484442;
  display: inline-block;
}
.third-line {
  border: 2px solid #ff2d47;
  border-radius: 3px;
  width: 42px;
  margin-top: 12px;
  margin-bottom: 11px;
  display: inline-block;
}
.boxes {
  margin-left: auto;
  margin-right: auto;
}
.box {
  width: 320px;
  height: 150px;
  background-color: #fbeae5;
  border-radius: 27px;
  position: relative;
  float: left;
  margin-right: 10px;
  margin-top: 10px;
  padding: 10px;
  margin-bottom: 30px;
  align-items: center;
  display: flex;
}
.scd-image {
  margin-left: 26px;
  position: relative;
  float: left;
  width: 90px;
}
.right-div {
  float: right;
  position: relative;
  width: 54%;
}

.box-head {
  font-size: 13px;
  margin-top: 13px;
  font-family: "Montserrat Bold";
  font-weight: 700;
  text-align: center;
}
.fourth-line {
  border: 1.5px solid #ff2d47;
  border-radius: 4px;
  margin-left: auto;
  margin-right: auto;
  width: 55px;
  margin-top: 4px;
  margin-bottom: 4px;
}
.nb-steps {
  font-family: "Montserrat Bold";
  font-weight: 900;
  font-size: 26px;
  position: relative;
  text-align: center;
}
.steps {
  position: relative;
  text-align: center;
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  font-size: 12px;
}
.last-line {
  font-size: 9px;
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  text-align: center;
}
.nb-months {
  font-family: "Montserrat Bold";
  font-weight: 900;
  font-size: 26px;
  text-align: center;
  position: relative;
}
.months {
  position: relative;
  text-align: center;
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  font-size: 12px;
}
.last-div {
  width: 21cm;
  /* height: 219px; */
  padding: 2px 2px 2px 20px;
  background-color: #fbeae5;
  align-items: center;
  display: flex;
  position: absolute;
  bottom: 0;
}
.questions-container {
  position: relative;
  flex: 2;
}
.questions {
  font-family: "Montserrat Black";
  font-weight: 900;
  color: #ff2d47;
  font-size: 20px;
}
.team {
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  font-size: 16px;
  margin-top: 10px;
}
.logo-container {
  flex: 1;
}
.contact-container {
  flex: 4;
  position: relative;
  float: left;
  text-align: center;
}
.email {
  font-family: "Montserrat Medium";
  font-weight: 400;
  font-size: 16px;
}
.phone {
  font-family: "IBM Plex Mono";
  font-weight: 400;
  font-size: 14px;
}
.whatsapp {
  font-family: "Montserrat Medium";
  font-weight: 400;
  font-size: 16px;
  margin-top: 10px;
}

.sub-footer-phone{
  font-family: "Montserrat Medium";
  font-size: 11px;
}

.page {
  width: 21cm;
  min-height: 29.7cm;
  /* padding: 2cm;
    margin: 1cm auto; */
  /* border: 1px #d3d3d3 solid; */
  border-radius: 0px;
  background: white;
  overflow: hidden;
}
/* @page {
    size: A4;
    margin: 0;
  } */
/* @media print {
    .page {
      margin: 0;
      border: initial;
      border-radius: initial;
      width: initial;
      min-height: initial;
      box-shadow: initial;
      background: initial;
      page-break-after: always;
    }
  } */

.tp-pdf-container {
  background-color: #fff;
  width: 100%;
  font-family: 'Eina04-Regular';
}

.tp-pdf-modal {
  width: 900px;
  margin: auto;
}

.tp-pdf-sub-container, .tp-pdf-sub-remote-container {
  display: flex;
  justify-content: space-between;
  padding: 50px 50px 0;
  /* column-gap: 50px; */
  flex-wrap: wrap;
}

.tp-pdf-sub-container-2, .tp-pdf-sub-container-3 {
  display: flex;
  flex-direction: column;
  padding: 50px;
  row-gap: 50px;
}

.tp-pdf-sub-container-3, .tp-pdf-sub-remote-container {
  padding: 0 50px 50px;
}

.tp-pdf-desc-container {
  display: flex;
  justify-content: center;
  padding: 0 50px;
  column-gap: 50px;
}

.tp-pdf-blue-container {
  align-items: center;
  background-color: #DFEFF2;
  padding-bottom: 50px;
}

.tp-pdf-blue-container-h1 {
  font-family: 'Eina04-Bold';
  font-size: 22px;
  color: #FF2C46;
  line-height: 35px;
}

.tp-pdf-blue-container-p {
  font-size: 18px;
}

.pdf-generated-container {
  padding: 10px 50px;
}

.pdf-generated-p {
  color: #FF2C46;
  font-size: 18px;
  text-align: right;
}

.pdf-generated-txt {
  font-family: 'Eina04-Bold';
}

.pdf-hr {
  border: 3px solid #DFEFF2;
  width: 100%;
}

.tp-pdf-question-title {
  font-family: 'Eina04-Bold';
  font-size: 22px;
}

.tp-pdf-question-points {
  padding: 0 20px;
}

.tp-pdf-question-points li {
  font-size: 18px;
}

.tp-pdf-time-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.tp-pdf-time-title-container {
  background-color:#DFEFF2;
  padding: 10px 20px;
  border-radius: 20px;
  margin-bottom: 10px;
}

.tp-pdf-time-title {
  font-family: 'Eina04-Bold';
  font-size: 20px;
}

.tp-pdf-time-count {
  font-family: 'Eina04-Bold';
  font-size: 25px;
  color: #FF2C46;
}

.tp-pdf-time-months {
  font-family: 'Eina04-Bold';
  font-size: 17px;
  color: #FF2C46;
}

.tp-pdf-desc-title {
  font-size: 22px;
}

.tp-pdf-desc-span {
  font-family: 'Eina04-Bold';
}

.tp-pdf-details-p {
  font-family: 'Eina04-SemiBold';
  font-size: 11px;
}

.tp-pdf-desc-paragraph {
  font-size: 20px;
  line-height: 25px;
}

.tp-pdf-desc-paragraph span {
  position: unset;
}

.tp-pdf-teeth-image-container {
  width: 100%;
}

.tp-pdf-teeth-image {
  width: 100%;
}

.tp-pdf-desc-sub-container, .tp-pdf-desc-red-sub-container {
  width: 300px;
  display: flex;
  border: 2px solid;
  border-radius: 20px;
  column-gap: 5px;
  align-items: center;
  padding: 0 10px;
}

.tp-pdf-desc-red-sub-container {
  border: 2px solid #FF2C46;
  color: #FF2C46;
}

.tp-pdf-desc-img {
  width: 50px;
}

.tp-pdf-desc-hr, .tp-pdf-desc-red-hr {
  height: 100%;
  border: 1px solid;
  margin: 0;
}

.tp-pdf-desc-red-hr, .tp-pdf-certificate-hr {
  border: 1px solid #FF2C46;
}

.tp-pdf-desc-text-container {
  padding: 5px 0;
}

.tp-pdf-desc-p {
  font-family: 'Eina04-SemiBold';
}

.tp-pdf-table {
  display: grid;
  border-bottom: 2px solid #FF2C46;
  width: 100%;
  padding: 1em 0;
  margin-bottom: 10px;
}

.tp-pdf-table-tr {
  display: flex;
  justify-content: space-around;
  border-bottom: 2px solid #FF2C46;
  padding: 0 0 10px;
  margin-bottom: 10px;
}

.tp-pdf-table-tr-data {
  display: flex;
  justify-content: space-between;
  padding-bottom: 10px;
  align-items: center;
}

.tp-pdf-table-td, .tp-pdf-table-td-center {
  display: flex;
  flex: 1;
  justify-content: flex-start;
  font-size: 16px;
  font-family: 'Eina04-Bold';
}

.tp-pdf-table-td-center {
  justify-content: center;
}

.tp-pdf-table-th, .tp-pdf-table-title {
  font-size: 17px;
  font-family: 'Eina04-Bold';
  color: #FF2C46;
  flex: 1;
}

.tp-pdf-table-title {
  font-size: 28px;
  margin-bottom: 10px;
}

.tp-pdf-table-desc {
  font-size: 20px;
  line-height: 22px;
  font-family: 'Eina04-SemiBold';
}

.tp-pdf-table-end-p {
  font-family: 'Eina04-Bold';
  font-size: 18px;
  margin-bottom: 10px;
}

.tp-pdf-table-end-p2 {
  font-size: 16px;
  margin-bottom: 50px;
}

.tp-pdf-clip-path-container {
  background-color: #DFEFF2;
}

.tp-pdf-bg-container {
  background: url('../../../src/img/procedures-wave-en.png');
}

.tp-pdf-case-bg-container {
  background: url('../../../src/img/cases-wave-en.png');
}

.tp-pdf-bg-ar-container {
  background: url('../../../src/img/procedures-wave-ar.png');
  height: 260px !important;
}

.tp-pdf-case-ar-bg-container {
  background: url('../../../src/img/cases-wave-ar.png');
}

.tp-pdf-footer-bg-container {
  background: url('../../../src/img/footer-wave.png');
  height: 50px !important;
  display: flex;
  justify-content: center;
  align-items: baseline;
}

.tp-pdf-bg-container, .tp-pdf-bg-ar-container {
  background-size: cover;
  background-repeat: no-repeat;
  height: 230px;
}

.tp-pdf-wave {
  /* width: 100%;
  overflow: hidden;
  line-height: 0; */
  width: 100%;
  overflow: hidden;
  line-height: 0;
  /* transform: rotate(180deg); */
}

.tp-pdf-wave svg {
  /* position: relative; */
  display: block;
  width: calc(124% + 1.3px);
  height: 100px;
}

.tp-pdf-wave .tp-pdf-wave-fill {
  fill: #DFEFF2;
}

.tp-pdf-inner-clip-path {
  display: flex;
  flex-direction: column;
  gap: 50px;
  padding: 0 50px 50px;
  margin-bottom: 50px;
}

.tp-pdf-single-procedure-container {
  display: flex;
  flex-direction: column;
}

.tp-pdf-single-procedure {
  display: flex;
  align-items: flex-start;
  column-gap: 20px;
}

.tp-pdf-single-procedure-icon {
  width: 50px;
}

.tp-pdf-single-procedure-img {
  align-self: flex-end;
  width: 150px;
}

.tp-pdf-single-procedure-title, .tp-pdf-single-procedure-desc {
  font-size: 19px;
  line-height: 22px;
}

.tp-pdf-single-procedure-title {
  font-size: 22px;
  font-family: 'Eina04-Bold';
  margin-bottom: 0.5rem;
}

.tp-pdf-sub-remote-container {
  align-items: center;
  column-gap: 2%;
}

.tp-pdf-remote-img {
  flex: 1;
  width: 50%;
}

.google-play, .app-store {
  width: 110px;
  height: 37px;
}

.tp-pdf-btn {
  display: none;
}

.tp-pdf-remote-container {
  flex: 1;
}

.tp-pdf-remote-h1 {
  color: #FF2C46;
  font-size: 30px;
  line-height: 35px;
  font-family: 'Eina04-Bold';
}

.tp-pdf-remote-p {
  font-size: 18px;
}

.tp-pdf-remote-img-container {
  margin-top: 10px;
  display: flex;
  column-gap: 10px;
}

.tp-pdf-certificate-img {
  width: 100px;
}

.tp-pdf-certificate-container {
  border: 2px solid #FF2C46;
  border-radius: 20px;
  padding: 10px 0;
  flex: 1;
}

.tp-pdf-certificate-h1, .tp-pdf-certificate-p {
  margin: auto 10px;
}

.tp-pdf-certificate-h1, .tp-pdf-case-p {
  font-size: 20px;
  color: #FF2C46;
  font-family: 'Eina04-Bold';
}

.tp-pdf-certificate-p {
  font-family: 'Eina04-SemiBold';
}

.tp-pdf-case-sub-container {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  margin-bottom: 20px;
}

.tp-pdf-case-hr {
  width: 67%;
  border: 3px solid #FF2C46;
  margin: 0;
}

.tp-pdf-case-imgs-container {
  display: flex;
  justify-content: space-between;
  column-gap: 2%;
}

.tp-pdf-case-img-container {
  display: flex;
  flex-direction: column;
}

.tp-pdf-case-img-p {
  font-size: 16px;
}

.tp-pdf-case-img {
  margin-bottom: 0.5rem;
  width: 100%;
}

.tp-pdf-cases-container {
  display: flex;
  flex-direction: column;
  row-gap: 50px;
  padding: 0 50px 50px;
  margin-bottom: 50px;
}

.tp-pdf-next-steps-container {
  justify-content: center;
  margin-bottom: 50px;
  padding: 20px 50px 0;
}

.tp-pdf-next-steps-ol p {
  display: flex;
  align-items: center;
  text-align: center;
  column-gap: 50px;
  font-size: 20px;
  font-family: 'Eina04-SemiBold';
}

.tp-pdf-next-steps-img {
  width: 30px;
}

.tp-pdf-next-steps-p {
  margin: 10px 0;
}

.tp-pdf-next-steps-title {
  text-align: center;
  font-size: 30px;
  font-family: 'Eina04-Bold';
  color: #FF2C46;
}

.tp-pdf-questions-container {
  background-color: #DFEFF2;
  text-align: center;
  padding: 20px 0;
}

.tp-pdf-questions-title {
  font-family: 'Eina04-Bold';
  font-size: 20px;
  line-height: 25px;
}

.tp-pdf-questions-desc {
  line-height: 25px;
  font-size: 16px;
}

.tp-pdf-social-media-container {
  display: flex;
  justify-content: space-around;
  padding: 10px 0;
}

.tp-pdf-social-media-txt {
  display: flex;
  align-items: center;
  column-gap: 10px;
  text-decoration: none;
  color: rgb(32, 34, 35);
}

.tp-pdf-tel {
  text-decoration: none;
  color: rgb(32, 34, 35);
}

.tp-pdf-initial-direction, .tp-pdf-initial-direction-pre {
  direction: initial;
}

.tp-pdf-initial-direction-pre {
  font-family: 'BalooBhaijaan-Regular';
  display: inline-block;
  margin: 0;
}

.tp-pdf-social-media-img {
  width: 30px;
}

.tp-pdf-phone-img {
  width: 25px;
}

.tp-pdf-footer-container {
  display: flex;
  flex-direction: column;
  background-color: #DFEFF2;
  padding: 30px 18px 18px;
  row-gap: 20px;
}

.tp-pdf-footer-sub-container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 10px;
}

.tp-pdf-footer-phone {
  display: flex;
  align-items: center;
  column-gap: 4px;
  font-size: 16px;
}

.tp-pdf-footer-phone-title {
  font-family: 'Eina04-SemiBold';
}

.tp-pdf-footer-curved {
  font-size: 20px;
  font-weight: bold;
  color: #FF2C46;
  text-align: center;
}

.tp-pdf-footer-id {
  font-size: 13px;
  color: #999;
}

.tp-pdf-footer-img {
  width: 50%;
  align-self: center;
}

.tp-pdf-procedures-table {
  padding: 0 30px;
}

.tp-pdf-procedures-table-p {
  margin: 0 30px;
}

.tp-pdf-redo-scan {
  width: 250px;
  display: flex;
  background-color: #FF2C46;
  border-radius: 10px;
  color: #fff;
  padding: 5px 5px 5px 10px;
  justify-content: space-between;
  height: fit-content;
}

.tp-pdf-redo-scan-img {
  width: 60px;
  height: 60px;
  background-color: #fff;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

.tp-pdf-ar {
  direction: rtl;
  text-align: right;
  font-family: 'BalooBhaijaan-Regular';
}

.tp-pdf-ar .tp-pdf-blue-container-h1 {
  direction: initial;
}

.tp-pdf-ar .tp-pdf-blue-container-p {
  display: inline-block;
}

.tp-pdf-ar .tp-pdf-blue-container-h1,
.tp-pdf-ar .pdf-generated-txt,
.tp-pdf-ar .tp-pdf-question-title,
.tp-pdf-ar .tp-pdf-time-title,
.tp-pdf-ar .tp-pdf-time-count,
.tp-pdf-ar .tp-pdf-time-months,
.tp-pdf-ar .tp-pdf-desc-span,
.tp-pdf-ar .tp-pdf-table-th,
.tp-pdf-ar .tp-pdf-table-title,
.tp-pdf-ar .tp-pdf-table-end-p,
.tp-pdf-ar .tp-pdf-single-procedure-title,
.tp-pdf-ar .tp-pdf-remote-h1,
.tp-pdf-ar .tp-pdf-certificate-h1,
.tp-pdf-ar .tp-pdf-case-p,
.tp-pdf-ar .tp-pdf-next-steps-title,
.tp-pdf-ar .tp-pdf-questions-title  {
  font-family: 'BalooBhaijaan-Bold';
}

.tp-pdf-ar .tp-pdf-details-p,
.tp-pdf-ar .tp-pdf-desc-p,
.tp-pdf-ar .tp-pdf-table-desc,
.tp-pdf-ar .tp-pdf-certificate-p,
.tp-pdf-ar .tp-pdf-next-steps-ol p,
.tp-pdf-ar .tp-pdf-footer-phone-title {
  font-family: 'BalooBhaijaan-SemiBold';
}


.tp-pdf-ar .tp-pdf-table-th {
  text-align: center;
}

.tp-pdf-ar .tp-pdf-table-td {
  direction: initial;
  text-align: left;
}

.tp-pdf-ar .tp-pdf-table-desc {
  display: inline-block;
}

.tp-pdf-ar .tp-pdf-remote-h1 {
  margin-bottom: 0.5rem;
}

.tp-pdf-ar .tp-pdf-next-steps-ol p {
  column-gap: 10px;
}

.tp-pdf-ar .tp-pdf-question-point-container {
  font-size: 18px;
  display: flex;
  align-items: center;
  column-gap: 5px;
}

.tp-pdf-ar .tp-pdf-question-point {
  font-size: 30px;
}


@font-face {
  font-family: "Symbio Black";
  src: url(../fonts/SymbioARLT-Black.woff2) format("woff2"),
    url(../fonts/SymbioARLT-Black.woff) format("woff");
}
@font-face {
  font-family: "Symbio Regular";
  src: url(../fonts/SymbioARLT-Regular.woff2) format("woff2"),
    url(../fonts/SymbioARLT-Regular.woff) format("woff");
}
@font-face {
  font-family: "Symbio Medium";
  src: url(../fonts/SymbioARLT-Medium.woff2) format("woff2"),
    url(../fonts/SymbioARLT-Medium.woff) format("woff");
}
.containerAr {
  width: 713px;
  margin: auto;
  background-color: #ffffff;
  padding-right: 10;
}

.first-headerAr {
  font-family: "Symbio Black", sans-serif;
  font-weight: 900;
  font-size: 29px;
  color: #484442;
  display: inline-block;
  text-align: right;
}
.lineAr {
  border: 2px solid #ff2d47;
  border-radius: 3px;
  width: 92px;
  margin-top: 15px;
  margin-bottom: 14px;
}
.textAr {
  font-family: "Symbio Medium", sans-serif;
  font-weight: 500;
  font-size: 21px;
  color: #484442;
  /* display: inline-block; */
  /* text-align: right; */
  float: right;
  direction: rtl;
}

.scd-containerAr {
  margin-top: 56px;
  display: flex;
  flex-direction: column;
  direction: rtl;
  /* margin: 0 auto; */
  /* margin-left: 53px; */
}
.scd-headerAr {
  font-family: "Symbio Black", sans-serif;
  font-weight: 700;
  font-size: 26px;
  color: #484442;
  /* display: inline-block; */
  text-align: right;
  float: right;
  direction: rtl;
}
.scd-lineAr {
  border: 2px solid #ff2d47;
  border-radius: 3px;
  width: 42px;
  margin-top: 12px;
  margin-bottom: 11px;
  display: inline-block;
}
.scd-textAr {
  font-family: "Symbio Medium", sans-serif;
  font-weight: 400;
  font-size: 16px;
  max-width: 18cm;
  text-align: center;
  float: right;
  padding-left: 15px;
  padding-right: 15px;
}
.red-textAr {
  color: red;
  font-size: 15px;
  font-family: "Symbio Medium", sans-serif;
  margin-right: 50px;
  margin-top: 10px;
  float: right;
}

.imageAr {
  /* margin-top: 42px;
  margin-bottom: 37px; */
  margin-left: auto;
  margin-right: auto;
  width: 50%;
  text-align: center;
}
.third-containerAr {
  /* margin-left: 53px; */
  /* min-width: 1000px; */
  display: flex;
  flex-direction: column;
  direction: rtl;
  margin-top: 56px;
}
.third-headerAr {
  font-family: "Symbio Black", sans-serif;
  font-weight: 700;
  font-size: 26px;
  color: #484442;
  display: inline-block;
  text-align: right;
}
.third-lineAr {
  border: 2px solid #ff2d47;
  border-radius: 3px;
  width: 42px;
  margin-top: 12px;
  margin-bottom: 11px;
  display: inline-block;
  text-align: right;
}
.boxAr {
  width: 320px;
  height: 150px;
  background-color: #fbeae5;
  border-radius: 27px;
  position: relative;
  float: right;
  margin-left: 10px;
  margin-top: 10px;
  padding: 10px;
  margin-bottom: 30px;
  align-items: center;
  display: flex;
}

.scd-imageAr {
  margin-left: 26px;
  position: relative;
  float: left;
  width: 90px;
}
.right-divAr {
  float: right;
  position: relative;
  width: 54%;
}
.box-headAr {
  font-size: 17px;
  margin-top: 13px;
  font-family: "Symbio Black", sans-serif;
  font-weight: 700;
  text-align: center;
}
.fourth-lineAr {
  border: 1.5px solid #ff2d47;
  border-radius: 4px;
  margin-left: auto;
  margin-right: auto;
  width: 55px;
  margin-top: 8px;
  margin-bottom: 6px;
}
.nb-stepsAr {
  font-family: "Symbio Black", sans-serif;
  font-weight: 900;
  font-size: 34px;
  position: relative;
  text-align: center;
}
.stepsAr {
  position: relative;
  text-align: center;
  font-family: "Symbio Black", sans-serif;
  font-weight: 700;
  font-size: 14px;
  margin-top: 6px;
}
.last-lineAr {
  font-size: 13px;
  font-family: "Symbio Regular", sans-serif;
  font-weight: 400;
  text-align: center;
  direction: rtl;
}

.nb-monthsAr {
  font-family: "Symbio Black", sans-serif;
  font-weight: 900;
  font-size: 34px;
  text-align: center;
  position: relative;
}
.monthsAr {
  position: relative;
  text-align: center;
  font-family: "Symbio Black", sans-serif;
  font-weight: 700;
  font-size: 14px;
}

.last-divAr {
  width: 21cm;
  /* height: 219px; */
  padding: 2px 20px 2px 2px;
  background-color: #fbeae5;
  align-items: center;
  display: flex;
  position: absolute;
  bottom: 0;
}

.questions-containerAr {
    position: relative;
    flex: 2;
}
.questionsAr {
  font-family: "Symbio Black", sans-serif;
  font-weight: 900;
  color: #ff2d47;
  font-size: 40px;
  text-align: right;
}
.teamAr {
  font-family: "Symbio Regular", sans-serif;
  font-size: 20px;
  float: right;
  margin-top: 20px;
}
.logo-containerAr {
  flex: 1;
}
.contact-containerAr {
  flex: 4;
  position: relative;
  float: left;
  text-align: center;
}
.emailAr {
  font-family: "Symbio Regular", sans-serif;
  font-size: 19px;
}
.phoneAr {
  font-family: "IBM Plex Mono";
  font-weight: 400;
  font-size: 14px;
  margin-top: 5px;
}

.footer-phone {
  display: flex;
  justify-content: center;
  gap: 10px;
}

.sub-footer-phone {
  flex-direction: column;
  display: flex;
  gap: 2px;
}

.whatsappAr {
  font-family: "Symbio Regular", sans-serif;
  font-size: 19px;
  margin-top: 10px;
}

.Polaris-DropZone--sizeExtraLarge {
  min-height: 8rem;
  max-height: 8rem;
}
.Polaris-DropZone-FileUpload__Image.Polaris-DropZone-FileUpload--sizeExtraLarge {
  width: 50px;
}
/* .Polaris-PositionedOverlay {
    top: 140px !important;
    left: 1500px !important;
  } */
.doctorsImage {
  min-height: 100vh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: rgba(255, 255, 255, 0.6);
  background-blend-mode: overlay;
}
.float {
  position: fixed;
  width: 60px;
  height: 60px;
  bottom: 40px;
  right: 40px;
  background-color: #25d366;
  color: #fff;
  border-radius: 50px;
  text-align: center;
  font-size: 30px;
  box-shadow: 2px 2px 3px #999;
  z-index: 100;
}

.my-float {
  margin-top: 16px;
}

.Polaris-Banner--withinContentContainer.Polaris-Banner--statusSuccess {
  align-items: center;
}
