.fade-enter {
    opacity: 0;
  }
  
  .fade-enter-active {
    opacity: 1;
    transition: opacity 300ms;
  }
  
  .fade-exit {
    opacity: 1;
  }
  
  .fade-exit-active {
    opacity: 0;
    transition: opacity 300ms;
  }
  
  .image-MessageBox img{
    width: 100%;
  }
  .image-MessageBox  > div:first-of-type{
    width: min-content;
  }
  .video-MessageBox  > div:first-of-type{
    width: 50%;
  }
  @media screen and (max-width: 800px) {
    .video-MessageBox  > div:first-of-type{
      width: 60%;
    }
  }