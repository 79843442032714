@font-face {
  font-family: "Eina03-Bold";
  src: url(../fonts/Eina03-Bold.ttf);
}
@font-face {
  font-family: "Eina03-Regular";
  src: url(../fonts/Eina03-Regular.ttf);
}
@font-face {
  font-family: "Eina03-SemiBold";
  src: url(../fonts/Eina03-SemiBold.ttf);
}

.external-designer-cards-wrapper {
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
  column-gap: .5rem;
}

.external-designer-card-wrapper {
  flex: 1;
}

.external-designer-layout-wrapper {
  display: flex;
}

.external-designer-layout-wrapper .Polaris-Layout__Section {
  display: flex;
  align-items: stretch; 
}

.external-designer-layout-wrapper .Polaris-Card {
  flex: 1;
}

.external-designer-select-wrapper {
  width: 100%;
  display: flex;
  column-gap: 1rem;
}

.external-designer-select-wrapper .css-b62m3t-container {
  flex: 1;
}

.whats-new-options-container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.whats-new-option-container {
  display: flex;
  gap: 1rem;
  align-items: center;
}

.whats-new-option-os {
  min-width: 15%;
}

.whats-new-option-version {
  width: 10%;
  height: 38px;
}

.whats-new-option-status {
  max-width: 60%;
}

.whats-new-list {
  width: 50%;
  text-align: center;
  margin-top: 20px;
}

.whats-new-media-list {
  text-align: center;
  margin-top: 20px;
}

.whats-new-item-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1rem;
}

.whats-new-item-statuses {
  display: flex;
  gap: 1rem;
}

.app-whats-new-media-item-container {
  display: flex;
  flex-direction: column;
  width: 45%;
  gap: 15px;
}

.app-whats-new-media-item-dropzone-container {
  display: flex;
}

.app-whats-new-media-item-thumbnail {
  width: 30%;
}

.app-whats-new-media-item-dropzone {
  width: 70%;
}

.app-whats-new-media-item-dropzone .Polaris-DropZone--sizeLarge {
  min-height: 75px;
}

.app-whats-new-media-item-dropzone .Polaris-DropZone-FileUpload {
  height: 75px;
}

.DraftEditor-editorContainer .public-DraftEditor-content h1{
  font-size: 2em!important;
  }
  
  .DraftEditor-editorContainer .public-DraftEditor-content h2{
  font-size: 1.5em!important;
  }
  
  .DraftEditor-editorContainer .public-DraftEditor-content h3{
  font-size: 1.7em!important;
  
  }
  
  .DraftEditor-editorContainer .public-DraftEditor-content h4{
  font-size: 1em!important;
  }
  
  .DraftEditor-editorContainer .public-DraftEditor-content h5{
  font-size: .83em!important;
  }
  
  .DraftEditor-editorContainer .public-DraftEditor-content h6{
  font-size: .67em!important;
  }


.custom-table-sticky .Polaris-DataTable__ScrollContainer {
  overflow: auto;
  position: relative;
  height: 30vh;
  border: 1px lightgray solid;
  border-radius: 5px;
}

.custom-table-sticky thead tr:nth-child(1){
  position: sticky;
  background-color: white;
  z-index: 10001;
  top: 0;
}

.custom-table-sticky .Polaris-DataTable__Cell--firstColumn{
  position: sticky;
  background-color: white;
  left: 0;
  z-index: 10000;
  min-width: 200px !important;
  border-right: 1px lightgray solid;
}

.approved-tps-wrapper, .pending-tps-wrapper {
  margin: 1.6rem 0;
}

.approved-tps-wrapper .Polaris-Card__Footer, .pending-tps-wrapper .Polaris-Card__Footer {
  padding: 2rem !important;
}

.pending-tps-wrapper .Polaris-DataTable__TableRow {
  cursor: pointer;
}

.pending-tps-wrapper .Polaris-DataTable__TableRow:hover {
  background-color: #F6F6F7;
}

.custom-dispatch-table-sticky .Polaris-DataTable__ScrollContainer {
    overflow: auto;
    position: relative;
    height: fit-content;
}

.custom-dispatch-table-sticky thead tr:nth-child(1) {
    position: sticky;
    background-color: white;
    z-index: 30;
    top: 0;
}

.custom-dispatch-table-sticky .Polaris-DataTable__Cell:first-child,
.custom-dispatch-table-sticky .Polaris-DataTable__Cell:nth-child(2),
.custom-dispatch-table-sticky .Polaris-DataTable__Cell:nth-child(3),
.custom-dispatch-table-sticky .Polaris-DataTable__Cell:nth-child(4),
.custom-dispatch-table-sticky .Polaris-DataTable__Cell:nth-child(5){
    position: sticky;
    background-color: white;
    z-index: 29;
}

.custom-dispatch-table-sticky .Polaris-DataTable__Cell:first-child {
    left: 0;
}

.custom-dispatch-table-sticky .Polaris-DataTable__Cell:nth-child(2) {
    left: 40px;
}

.custom-dispatch-table-sticky .Polaris-DataTable__Cell:nth-child(3) {
    left: 150px;
}

.custom-dispatch-table-sticky .Polaris-DataTable__Cell:nth-child(4) {
    left: 280px;
}

.custom-dispatch-table-sticky .Polaris-DataTable__Cell:nth-child(5) {
  border-right: 2px lightgray solid;
  left: 360px;
}

#react-select-4-listbox {
  z-index: 99 !important;
}

.tracking-buttons-wrapper {
  display: flex;
  column-gap: 5px;
}

.tracking-buttons-wrapper .Polaris-Button {
  min-height: unset;
  padding: 5px 10px;
}

.itemClick {
  cursor: pointer;
  height: 100%;
}
.itemExportClick {
  cursor: pointer;
  display: flex;
  flex-direction: row;
}
.Polaris-EmptySearchResult__Image {
  width: 300px;
  height: 300px;
}
.Polaris-ResourceList__EmptySearchResultWrapper {
  padding-bottom: 0px;
  padding-top: 0px;
}
.Polaris-Modal-Dialog__Modal {
  /* background-color: transparent; */
  box-shadow: none;
}
.iprsheet .Polaris-Modal-Dialog__Modal{
  min-width: 80vw !important;
}

.Polaris-Modal-CloseButton--withoutTitle {
  visibility: hidden;
}

.react-tag-input__input {
  font-size: 1.5em;
}

.react-tag-input__tag{
  font-size: 1.4em;
}

.react-tag-input__tag__remove{
  height: 1.7em;
  width: 1.6em;
}

.react-tagsinput-tag {
  background-color: #123123;
  border-radius: 2px;
  border: 1px solid #f0f0f0;
  color: #f0f0f0;
  display: none;
  font-family: sans-serif;
  font-size: 13px;
  font-weight: 400;
  margin-bottom: 5px;
  margin-right: 5px;
  padding: 5px;
}

.clinic-communication .Polaris-DataTable__Cell--firstColumn{
  min-width: 200px;
}

.react-tag-input{
  margin-top: 3px;
}

.react-tag-input__input::placeholder{
  font-size: 13px; color: rgb(187, 182, 182);
}

#saveDiv > .Polaris-PageActions {
  border-top: none;
}

.Polaris-DropZone-FileUpload__Button {
  display: none;
}
 .date-container .Polaris-Connected__Item{
  z-index: 0 !important;
}
.Polaris-Filters-ConnectedFilterControl__MoreFiltersButtonContainer {
  display: none;
}

.Polaris-Popover__Section {
  min-width: 200px;
  max-height: 100px;
}
.Polaris-DataTable__Cell .Polaris-ButtonGroup {
  float: right;
}
.Polaris-Modal-CloseButton {
  display: none;
}
.Polaris-TopBar {
  background-color: rgba(51, 51, 51, 1);
}
.Polaris-TopBar-UserMenu__Name {
  color: white;
}
.spinnerContainer {
  height: 100px;
  width: 100%;
  position: relative;
  display: table;
}
.retrievebutton:hover{
  opacity: 0.8!important;
}
.retrievebutton1{
  opacity: 0.7
}

.vertical-center {
  text-align: center;
  display: table-cell;
  vertical-align: middle;
}
.Polaris-ButtonGroup {
  justify-content: center;
  flex-wrap: nowrap;
}

.circleDiv {
  width: 30px;
  height: 30px;
  background: transparent;
  border: 2px solid black;
  border-radius: 50%;
  text-align: center;
  display: table-cell;
  vertical-align: middle;
}
.MuiPaginationItem-sizeLarge {
  font-size: 1.2rem !important;
}
.MuiPaginationItem-sizeLarge .MuiPaginationItem-icon {
  font-size: 2rem !important;
}
.MuiPagination-ul {
  display: inline-flex !important;
}
/* .Polaris-Thumbnail--sizeLarge {
  width: 100%;
} */
.tooltip {
  margin-left: 10px;
  width: 20px;
  height: 20px;
  cursor: pointer;
}
.checkbox-wrapper{
  display: flex;
  flex-direction: column;
  row-gap: 5px;
}
.rowDiv {
  display: flex;
  flex-direction: row;
}
.procedure-container {
  display: flex;
  padding: 8px 18px;
  column-gap: 1rem;
  background-color: #f9f9f9;
  align-items: center;
}
.appt-title {
  font-weight: 700;
}
.tracking-actions-container {
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
  height: 200px;
  overflow-y: scroll;
  background-color: #eeeeee;
  padding: 0 10px;
}

/* width */
.tracking-actions-container::-webkit-scrollbar {
  width: 6px;
}

/* Track */
.tracking-actions-container::-webkit-scrollbar-track {
  background: #f1f1f1; 
}
 
/* Handle */
.tracking-actions-container::-webkit-scrollbar-thumb {
  background: #888; 
  border-radius: 20px;
}

/* Handle on hover */
.tracking-actions-container::-webkit-scrollbar-thumb:hover {
  background: #555; 
}

.tracking-dropdown-container, .tracking-textfield-container {
  width: 100%;
  display: flex;
  column-gap: 2rem;
}
.tracking-dropdown-container .MuiFormControl-root, .tracking-textfield-container .MuiFormControl-root{
  width: 100%;
}
.tracking-dropdown-container .MuiFormControl-root label, .tracking-textfield-container .MuiFormControl-root label {
  font-family: "Open Sans", sans-serif;
  font-weight: bold;
  font-size: 14px;
  left: 5px !important;
}
.tracking-textfield-container .MuiFormControl-root label {
  top: -3px !important;
}
.tracking-dropdown-select {
  font-size: 14px !important;
}
.tracking-dropdown-select div{
  padding: 5px !important;
}
.tracking-btn {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.journey-images-wrapper {
  display: flex;
  margin-bottom: 7vh;
}
.journey-image-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 1rem;
  flex: 1;
}
.journey-dropzone-wrapper {
  width: 8rem;
}
.bulk-select-container {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-bottom: 20px;
  gap: 10px;
}
.bulk-select-container .Polaris-Button {
  width: 150px;
}
.button-delete {
  display: flex;
  width: 8rem;
}
.journey-image-p {
  font-weight: 600;
  text-align: center;
}
.multiple-value-text-input input,
.multiple-value-text-input label {
  height: 30px;
}
.tp-banner{
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 15px 0;
  row-gap: 10px;
  align-items: flex-end;
}

.tp-banner button{
  width: fit-content;
}

.tp-banner1{
  position: relative;
  padding-top: 15px;
  text-align: left;
  padding-bottom: 15px;
}
.spinner {
  display: block;
  width: 70px;
  margin: 5% auto;
  height: 70px;
  border: 7px solid rgba(134, 204, 187, 0.336);
  border-radius: 50%;
  border-top-color: rgb(0,128,96);
  animation: spin 1s linear infinite;
}
.spinner1 {
  display: block;
  width: 15px;
  height: 15px;
  border: 3px solid rgba(134, 204, 187, 0.336);
  border-radius: 50%;
  border-top-color: rgb(0,128,96);
  animation: spin 1s linear infinite;
}
.spinner2 {
  display: block;
  width: 15px;
  height: 15px;
  border: 3px solid rgba(134, 204, 187, 0.336);
  border-radius: 50%;
  border-top-color: rgb(0,128,96);
  animation: spin 1s linear infinite;
}

.message-no-result{
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: center;
}

.message-no-result .message-no-result-img{
  width:8%
}

.message-no-result .message-no-result-img img{
  width:100%
}
td.plan-column.button{
  min-width: 5%;
  display: flex;
  justify-content: center;
  align-items: center;
}
th.plan-header.button{
  white-space:nowrap;
  min-width:5%;
}
td.plan-column{
  display: flex;
  justify-content: center;
  align-items: center;
}
th.plan-header{
  white-space:nowrap;
  max-width:10%;
}
td.plan-column.xl{
  width: 55%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.plan-select >div{
  width: 100%;
}
th.plan-header.xl{
  white-space:nowrap;
  width:50%;
  max-width: unset;
}

.shop-coupon-products-wrapper #react-select-2-listbox {
  z-index: 30;
}

.shipping-header {
  white-space: nowrap;
  width: 20%;
}

.shipping-column {
  display: flex;
  width: 20%;
  justify-content: center;
  align-items: center;
}
.shop-product-header {
  white-space: nowrap;
  width: 25%;
}

.shop-product-column {
  display: flex;
  width: 25%;
  justify-content: center;
  align-items: center;
}

.shop-products-wrapper {
  margin-bottom: 20px;
}

.button-wrapper {
  text-align: end;
  margin-bottom: 20px;
}

.shop-product-images-wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 2rem;
}

.procedures-profile-th,.procedures-profile-th-l,.procedures-profile-th-xl,.procedures-profile-th-s{
  border-bottom: 1px solid black;
  border-collapse: collapse;

}
.procedures-profile-td-s,.procedures-profile-th-s{
  min-width: 10%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 4px;
  text-align: center;
}
.procedures-profile-td,.procedures-profile-th{
  min-width: 15%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 4px;
  text-align: center;
  background-color: #fff;
}
.procedures-profile-th-l,.procedures-profile-td-l{
  min-width: 20%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 4px;
  text-align: center;
}
.procedures-profile-td-l,.procedures-profile-td,.procedures-profile-td-xl,.procedures-profile-td-s{
  border-bottom:1px solid #ddd;
  padding: 14px 4px;
}
.procedures-profile-th-xl,.procedures-profile-td-xl{
  min-width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 4px;
  text-align: center;
}
.case-records-files-wrapper{
  padding: 0 2rem;
}
.case-records-table{
  display: grid;
  width: 100%;
  overflow-x: auto;
  overflow-y: hidden;
}
.case-records-td, .case-records-td-l, .case-records-td-m, .case-records-td-s{
  border-bottom: 1px solid #ddd;
  padding: 14px 4px;
}
.case-records-td, .case-records-th{
  min-width: 12%;
  display: flex;
  flex-direction: column;
  padding: 4px;
  text-align: center;
}
.case-records-th, .case-records-th-l, .case-records-th-m, .case-records-th-s{
  border-bottom: 1px solid black;
  border-collapse: collapse;
  justify-content: center;
}
.case-records-th-l, .case-records-td-l, .case-records-th-m, .case-records-td-m, .case-records-th-s, .case-records-td-s{
  min-width: 20%;
  display: flex;
  flex-direction: column;
  padding: 4px;
  text-align: center;
}
.case-records-th-m, .case-records-td-m{
  min-width: 11%;
}
.case-records-th-s, .case-records-td-s{
  min-width: 8%;
}

.manual-report-entries-container {
  display: flex;
}

.manual-report-entries-table {
  position: relative;
  display: grid;
  width: 100%;
  overflow: auto;
}

.manual-report-entries-table-thead {
  position: sticky;
  top: 0;
  background-color: white;
  z-index: 30;
}

.manual-report-entries-table-row th{
  background-color: white;
}

.manual-report-entries-table-row {
  display: flex !important;
}

.clinic-report-manual-user-input {
  display: flex;
  align-items: center;
}

.clinic-report-manual-input {
  font-size: 16px !important;
}

.clinic-report-manual-icon {
  cursor: pointer;
}

.clinic-report-manual-icon:hover {
  opacity: 0.8;
}

.clinic-report-manual-proc-select {
  width: 100%;
}

.clinic-report-manual-transaction-select {
  width: 50px;
  margin: 0 auto;
}

.clinic-report-manual-date-picker {
  padding: 8px;
  border: none;
  border-bottom: 1px solid;
}

.clinic-report-manual-date-picker:hover {
  border-bottom: 2px solid;
  margin-bottom: -1px;
}

.clinic-report-manual-date-picker:focus {
  outline: none;
  border-bottom: 2px solid blue;
  margin-bottom: -1px;
}

.clinic-report-manual-user-id {
  display: flex;
  flex-direction: column;
}

.clinic-report-manual-user-id-error {
  color: red;
}

.clinic-report-manual-actions {
  margin-top: 2rem;
  display: flex;
  justify-content: end;
}

td.plan-column p{
  font-size: 1em;
}
.plan-field .MuiInput-input{
  font-size: 1.2em;
  text-align: center;
}
.plan-button{
  display: flex;
  min-width: 15%;
}
.plan-button button{
  padding: 0.5rem 3rem;
  margin :0 1rem;
  color: white;
  font-weight:500;
  border-radius:3px;
  border:none transparent
}
@keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
  }
}

.badge-wrapper-flex-wrap-gap{
  display: flex;
  flex-wrap: wrap; 
  justify-content: center;
  gap: 5px
}

.files-info-wrapper {
  cursor: pointer;
  display: flex;
  justify-content: center;
}

.usersidenav{
  display:grid; 
  position:fixed; 
  top:0; 
  background-color:transparent; 
  border-right:1px lightgray solid; 
  width:190px; 
  height:100%; 
  overflow-y: scroll; 
  box-shadow: 1px 1px 5px lightgray;
  padding-top:40px;
  padding-bottom: 100px;
  animation: openBar .05s ease-in-out forwards
}
@keyframes openBar {
  0%{
    width:50px; 
  }
  50%{
    width:100px; 

  }
  100%{
    width:190px; 
  }
}
@keyframes closeBar {
  0%{
    width:190px; 
  }
  50%{
    width:100px; 

  }
  100%{
    width:50px; 
  }
}
.usersidenavclosed{
  display:grid; 
  position:fixed; 
  top:0; 
  background-color:transparent; 
  border-right:1px lightgray solid; 
  box-shadow: 1px 1px 5px lightgray;
  width:50px; 
  height:100%; 
  overflow-y: scroll; 
  padding-top:40px;
  padding-bottom: 100px;
  animation: closeBar .05s ease-in-out forwards
}
.usersidenav p {
  padding-left: 20px;
  padding-top: 30px;
  padding-bottom: 15px;
  border-bottom:1px lightgray solid;
  color: white;
  cursor: pointer;
  position: fixed;
  overflow: hidden;
  z-index: 100;
  background-color: rgb(0 128 96);
  height:60px;
  animation: openBar .05s ease-in-out forwards;
  width: 189px;
}

.usersidenavclosed p {
  /* padding-left: 20px; */
  overflow: hidden;
  padding-top: 30px;
  padding-bottom: 15px;
  border-bottom:1px lightgray solid;
  position: fixed;
  cursor: pointer;
  z-index: 100;
  background-color: rgb(0 128 96);
  color: white;
  animation: closeBar .05s ease-in-out forwards;
  width: 49px;
}
.usersidenav div{
  display: grid;
  margin-top: 75px;
}
.usersidenavclosed div{
  display: grid;
  margin-top: 75px;
}
.usersidenav a {
  text-decoration: none;
  font-size: 12px;
  padding: 10px;
  padding-left: 10px;
  color:black;
  cursor: pointer;
  font-weight: 400;
}

.iconsDiv {
  display:grid; 
  grid-template-columns:30px 140px; 
  gap:1px; 
  height:20px;
  font-size: 12px;
  overflow-x: hidden;
}

.sidenav-title-wrapper{
  display: flex;
  cursor: pointer;
  flex-direction: row;
  justify-content: space-between;
  padding: 5px 20px;
}

.usersidenavclosed a {
  text-decoration: none;
  font-size: 12px;
  padding: 10px;
  padding-left: 10px;
  color:black;
  width:49px;
  cursor: pointer;
  font-weight: 400;
}
.usersidenav::-webkit-scrollbar, .usersidenavclosed::-webkit-scrollbar{
  display:none;
}
.usersidenav a:hover{
  text-decoration: none;
  background-color: #e7e7e7;
  font-weight: 400;
}

.usersidenavclosed a:hover{
  text-decoration: none;
  background-color: #e7e7e7;
  font-weight: 400;
}
.clinic-typo-styling{
  font-weight: 600!important;
  line-height: 2.4rem;
  margin: 0;
}
.clinic-address{
  font-size: 1rem!important;
}

.clinic-flex-styling{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap:20px;
  width: 100%;
} 

.clinic-title{
  font-size: 1.4rem!important;
}

.clinic-margin-bottom{
  margin-bottom: '1rem'
}

.clinic-card-styling{
  position: relative;
  background-color:#fff;
  justify-content:space-between; 
  display:flex; 
  flex-direction:column;
  border-radius:5px; 
  padding:2rem; 
  width: 290px;
  box-shadow: 0px 0px 5px #8888;
}

.clinic-card-styling .top-line{
  position:absolute;
  top:0;
  left:0;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  background: #FF2C46; 
  height:0.5rem; 
  width: 100%;
}

/* Tags Colors */
.clinic-status-light span{
  background-color: #99FFFF !important;
  color: #000000;
}

.clinic-status-medium span{
  background-color: #E0B0FF !important;
  color: #ffffff;
}

.clinic-status-medium-plus span{
  background-color: #c563ff !important;
  color: #ffffff;
}

.clinic-status-complex span{
  background-color: #F77FBE !important;
  color: #ffffff;
}

.clinic-status-complex-plus span{
  background-color: #1F75FE !important;
  color: #ffffff;
}

.clinic-status-good span{
  background-color: #76FF7A !important;
  color: #000000;
}

.linked-user-status span {
  background-color: #000000 !important;
  color: #ffffff;
}

.clinic-status-overdue span{
  background-color: #FFFF24 !important;
  color: #000000;
}

.clinic-status-doubtful span{
  background-color: #FF9966 !important;
  color: #ffffff;
}

.clinic-status-written-off span{
  background-color: #FF4F00 !important;
  color: #ffffff;
}

.written-off-badge span {
  background-color: #808080 !important;
  color: #ffffff;
}

.basma-pro-tag span {
  background-color: #47849A !important;
  color: #ffffff;
}

.styled-table {
  border-collapse: collapse;
  margin: 25px 0;
  font-size: 0.9em;
  font-family: sans-serif;
  min-width: 100%;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
}
.styled-table thead tr {
  background-color: rgb(0 128 96);
  color: #ffffff;
  text-align: center;
}
.styled-table th,
.styled-table td {
    padding: 12px 15px;
    text-align: center;

}
.styled-table tbody tr {
  border-bottom: 1px solid #dddddd;
}

.styled-table tbody tr:nth-of-type(even) {
  background-color: #f3f3f3;
}

.styled-table tbody tr:last-of-type {
  border-bottom: 2px solid rgb(0 128 96);
}
.styled-table tbody tr.active-row {
  font-weight: bold;
  color: rgb(0 128 96);
}
.blur {
  background: rgba(0, 0, 0, 0.4);
  width: 100%;
  height: 100%;
  position: fixed;
}
.nocsroll {
  overflow-y: hidden;
}
.navHeaderWrap {
  position: sticky;
  width: 100%;
  top: 55px;
  background-color: #fff;
  border-right:1px lightgray solid; 
  box-shadow: 1px 1px 5px lightgray;
  left: 15%;
  z-index: 31;
  height: fit-content;
}
/******/
.navbar {
  position: relative;
  height: 61.6px; /***What i tweak from reactstrap*/
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 0.5rem 1rem;
}
.navbar-brand {
  display: inline-block;
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  width: 170px; /***What i tweak from reactstrap*/
  margin-right: 1rem;
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap;
}
/******/
.bodyWrap {
  margin-top: 63px;
  position: relative;
  z-index: 100;
}
.sidenav {
  height: fit-content;
  width: 50px;
  position: fixed;
  z-index: 20!important;
  top: 50px!important; /*This give space for navbar at top*/
  margin-left: 240px;
  background-color: #fff!important;
  border-right:1px lightgray solid; 
  box-shadow: 1px 1px 5px lightgray;
  overflow-x: hidden;
  transition: 0.5s;
  padding: 15px 0 50px;
  box-sizing: border-box;
}
.main {
  transition: margin-left 0.5s;
  margin-top: 20px;
  box-sizing: border-box;
  height: 100%;
  display: block;
}
/*******************/

.sidenavOpen {
  height: 100%;
  width: 0;
  position: fixed;
  z-index: 1;
  background-color: #fff;
  top: 62px; /*This give space for navbar at top*/
  left: 0;
  background-color:transparent; 
  border-right:1px lightgray solid; 
  box-shadow: 1px 1px 5px lightgray;
  overflow-x: hidden;
  transition: 0.5s;
  padding-top: 15px;
  width: 200px;
}

.mainShrink {
  transition: margin-left 0.5s;
  padding: 16px;
  height: 100%;
  display: block;
}

/*******************/
.sidenavClose {
  height: 100%;
  width: 0;
  position: fixed;
  z-index: 11;
  background-color: #fff;
  top: 62px; /*This give space for navbar at top*/
  left: 0;
  background-color:transparent; 
  border-right:1px lightgray solid; 
  box-shadow: 1px 1px 5px lightgray;
  overflow-x: hidden;
  transition: 0.5s;
  padding-top: 15px;
  width: 60px;
}

.mainExpand {
  transition: margin-left 0.5s;
  padding: 16px;
  height: 100%;
  overflow: hidden;
  display: block;
}
/*******************/
.sidenav a {
  padding: 15px 15px;
  text-decoration: none;
  font-size: 1.2rem;
  color: #818181;
  display: block;
  cursor: pointer;
  transition: 0.3s;
}

.sidenav a:hover {
  color: #FF2C46;
}
.hidex {
  display: none !important;
}

.page-header {
  font-size: 24px;
  margin-bottom: 20px;
  padding: 5px;
}

.full-width-tp-section {
  width: 100%;
}

.actions-notifications-container {
  display: flex;
  flex-direction: column;
  row-gap: 2rem;
}

.threeD-count-p {
  font-weight: bold;
  cursor: pointer;
}

.tp-btn-container {
  display: flex;
  justify-content: flex-end;
}

.imposed-imgs-container {
  display: flex;
  justify-content: space-around;
  width: 100%;
  column-gap: 2rem;
}

.imposed-sub-container {
  flex: 1;
}

.tp-page-header {
  font-size: 24px;
  padding: 5px;
}

.smile-plan-intro-container {
  padding: 2rem;
  display: flex;
  flex-direction: column;
  row-gap: 1.6rem;
}

.smile-plan-intro-1 {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.threeD-files-container {
  margin-top: 4vh;
}

.threeD-help-modal {
  padding: 5rem;
  display: flex;
  flex-direction: column;
  row-gap: 2rem;
}

.tp-nickname-container {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  column-gap: 2rem;
}

.tp-nickname-container > div {
  width: 50%;
}

.end-section-div {
  display: flex;
  justify-content: flex-end;
}

.tp-main-container {
  width: 100%;
}

.rich-text-editor .notranslate.public-DraftEditor-content {
  height: 500px;
  overflow: hidden;
  overflow-y: auto;
}
.RichTextEditor_job .notranslate.public-DraftEditor-content {
  height: 200px !important;
  overflow: hidden;
  overflow-y: auto;
}
.RichTextEditor_clinics .notranslate.public-DraftEditor-content {
  height: 150px !important;
  overflow: hidden;
  overflow-y: auto;
}

.shop-rich-text-wrapper {
  display: flex;
  column-gap: 10px;
}

.shop-rich-text-sub-wrapper {
  flex: 1;
  display: flex;
  flex-direction: column;
  row-gap: 15px;
}

.shop-rich-text-editor .notranslate.public-DraftEditor-content {
  height: 400px;
  overflow: hidden;
  overflow-y: scroll;
}

.dental-checkup-row {
  display: flex;
  padding: 0 5px;
}

.dental-checkup-td {
  width: 100%;
}

.tp-requirements-checkboxes-container {
  display: flex;
}

.tp-requirements-checkboxes-container label{
  flex: 1;
}

.tp-checkboxes-select {
  width: 40%;
}

.empty-state-image {
  display: inline-block;
  width: 50px;
}

.sidenav .closebtn {
  position: absolute;
  top: 0;
  right: 25px;
  font-size: 36px;
  margin-left: 50px;
}

.Polaris-TopBar-Menu__Activator:hover .Polaris-TopBar-UserMenu__Name {
  color: black;
}
.Polaris-TopBar__Contents{
  column-gap: 10px;
}

.status-crm .Polaris-Card {
  height: 100%;
}
.basic_clinic{
  background-color: #ff2c4c !important;
  color: white;
  padding-inline: 8px;
  padding-top: 3px;
  padding-bottom: 3px;
  font-size: 8px;
  border-radius: 20px;
}
.role{
  font-weight: 400;
}
/* @media screen and (min-width: 1024px) {
  .sidenav {
    width: 250px;
  }
  .sidenav a {
    font-size: 18px;
  }
  .main {
    margin-left: 250px;
  }
} */

/* @media screen and (max-height: 450px) {
  .sidenav {
    padding-top: 15px;
  }
  .sidenav a {
    font-size: 18px;
  }
} */

@media screen and (max-width: 425px) {
  .bodyWrap {
    margin-top: 53px;
    position: relative;
    z-index: 100;
  }
  .sidenavOpen {
    top: 52px;
  }
}
@media screen and (max-width: 766px) {
  .HeaderStickyResp{
    margin-left: 0px!important;
  }
  .navbar {
    position: relative;
    height: auto;
  }
}
@media screen and (max-width: 768px) {
  .sidenav {
    width: 150px;
  }
  .main {
    margin-left: 150px;
  }

  .hidex {
    display: block !important;
  }
  .sidenavOpen {
    width: 250px;
  }
  .mainShrink {
    margin-left: 0px;
  }

  .sidenavClose {
    width: 0px;
  }

  .mainExpand {
    margin-left: -10px;
  }
  .sidenav a {
    font-size: 18px;
  }
}
.hmbger {
  color: #111;
  float: right;
}

@media screen and (max-width: 768px) {
  .hmbger {
    color: #111;
    float: left;
    margin-right: 10px;
  }
  
.sidenav {
  display:none;
}
}

.sidenav::-webkit-scrollbar, .usersidenavclosed::-webkit-scrollbar{
  display:none;
}
.image--0 .ril-image-current, .image-0 .ril-image-current {
  transform: rotate(0deg)!important;
}
.image--90 .ril-image-current, .image-90 .ril-image-current {
  transform: rotate(90deg)!important;
}

.image--180 .ril-image-current, .image-180 .ril-image-current {
  transform: rotate(180deg)!important;
}
.image--270 .ril-image-current, .image-270 .ril-image-current  {
  transform: rotate(270deg)!important;
}
.image--360 .ril-image-current, .image-360 .ril-image-current {
  transform: rotate(360deg)!important;
}
.payment-wrapper .Polaris-DataTable__Cell{
  border-left: 1px rgba(203, 203, 203, 0.655) solid;
}