.teleConsultContainer {
  font-family: "Eina03-Regular", sans-serif;
  margin-bottom: 20px;
}

.basmalogo {
  /* write the css so that the logo is in the middle of the page */
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.loading-container > .loading {
  border: 2px solid #f3f3f3;
  border-top: 3px solid #ff2d47;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  animation: spin 1s linear infinite;
}

.error-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 10px;
  color: #ff2d47;
  font-family: "Eina03-Regular", sans-serif;
}

.loading-container {
  margin-top: 40px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
  font-family: "Eina03-Regular", sans-serif;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@font-face {
  font-family: "Eina03-Regular";
  src: url("../../fonts/Eina03-Regular.ttf");
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: "Eina04-Bold";
  src: url("../../fonts/Eina04-Bold.ttf");
  font-weight: 800;
  font-display: swap;
}
@font-face {
  font-family: "Eina03-SemiBold";
  src: url("../../fonts/Eina03-SemiBold.ttf");
  font-weight: 600;
  font-display: swap;
}

@font-face {
  font-family: "Eina03-Bold";
  src: url("../../fonts/Eina03-Bold.ttf");
  font-weight: 800;
  font-display: swap;
}
