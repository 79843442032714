.ticketing-filter-parent{
    width: 20%;
    background-color:white;
    border-left: 1px solid #cfd7df;
    padding: 10px 2px 30px 2px;
    position: fixed;
    right: 0;
    bottom: 0;
    top: 7.6rem;
}
.filter-content{
    display: flex;
    gap: 20px;
    flex-direction:  column;
    width: 80%;
    margin: auto; 
}
.filter-body{
    display: flex;
    gap: 20px;
    flex-direction: column;
}