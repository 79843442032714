body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.blog-img-dropzone-wrapper .Polaris-DropZone--sizeLarge {
  min-height: 9rem !important;
}

@font-face {
  font-family: "Eina03-Regular";
  src: url("fonts/Eina03-Regular.ttf");
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: "Eina04-Regular";
  src: url("fonts/Eina04-Regular.ttf");
  /* font-weight: 800; */
  font-display: swap;
}

@font-face {
  font-family: "Eina04-SemiBold";
  src: url("fonts/Eina04-SemiBold.ttf");
  /* font-weight: 800; */
  font-display: swap;
}

@font-face {
  font-family: "Eina04-Bold";
  src: url("fonts/Eina04-Bold.ttf");
  /* font-weight: 800; */
  font-display: swap;
}

@font-face {
  font-family: "Eina03-SemiBold";
  src: url("fonts/Eina03-SemiBold.ttf");
  font-weight: 600;
  font-display: swap;
}

@font-face {
  font-family: "Eina03-Bold";
  src: url("fonts/Eina03-Bold.ttf");
  font-weight: 800;
  font-display: swap;
}

@font-face {
  font-family: "BalooBhaijaan-Regular";
  src: url("fonts/BalooBhaijaan2-Regular.ttf");
  font-display: swap;
}
@font-face {
  font-family: "BalooBhaijaan-SemiBold";
  src: url("fonts/BalooBhaijaan2-SemiBold.ttf");
  font-display: swap;
}
@font-face {
  font-family: "BalooBhaijaan-Bold";
  src: url("fonts/BalooBhaijaan2-Bold.ttf");
  font-display: swap;
}
@font-face {
  font-family: "BalooBhaijaan-Medium";
  src: url("fonts/BalooBhaijaan2-Medium.ttf");
  font-display: swap;
}
@font-face {
  font-family: "BalooBhaijaan-ExtraBold";
  src: url("fonts/BalooBhaijaan2-ExtraBold.ttf");
  font-display: swap;
}
@font-face {
  font-family: "BalooBhaijaan";
  src: url("fonts/BalooBhaijaan2.ttf");
  font-display: swap;
}

.case-record-drop-zone-design .Polaris-DropZone--sizeLarge{
  min-height: unset;
}
.case-record-drop-zone-design .Polaris-DropZone--sizeLarge{
  min-height: unset;
}
.online{
  background-color: #59d359;
  width: 9px;
  height: 9px;
  border-radius: 50%;
  display: inline-block;
}
.offline{
  background-color: #FF2C46;
  width: 9px;
  height: 9px;
  border-radius: 50%;
  display: inline-block;
}
.clinic_id{
  font-weight: 300;
  padding-top: 3px;
}