.CircularProgressbar .CircularProgressbar-path {
	 stroke: #ff2d47;
}
 .CircularProgressbar .CircularProgressbar-text {
	 fill: #ff2d47;
}

.load-model-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    cursor: pointer;
}

.threeD-loader{
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
}

.threeD-msg{
    font-size: 20px;
    font-family: Montserrat, sans-serif;
    padding-bottom: 15px;
    font-weight: 300;
    margin: 0;
    text-align: center;
}

.threeD-loader > div {
    width: 130px !important;
}

.play-btn{
	width: 75px;
    height: 75px;
}
 .model3d #canvas {
	 margin: auto;
	 margin-top: 40px;
	 /* border: 2px solid #ff2d47;
	 border-radius: 5px; */
	 width: fit-content;
}
 .model3d .container3d {
	 position: relative;
	 display: flex;
	 flex-direction: column;
	 align-items: center;
}
 .model3d .play-btn {
	 margin: 0 10px;
}
 .model3d .play-btn svg {
	 width: 75px;
	 height: 75px;
}
 .model3d .stop-btn {
	 display: none;
}
 .model3d a:hover {
	 opacity: 0.7;
}
 .model3d .timeline .digits {
	 display: flex;
	 align-items: center;
	 justify-content: center;
}
 .model3d .step-controls {
	 width: 100%;
	 justify-content: center;
	 align-items: center;
	 flex-direction: column;
	 position: absolute;
	 bottom: 10px;
}

.model3d .step-controls-play {
	position: absolute;
	top: 10px;
	width: 100%;
}
 
 .model3d .step-controls .system {
	 display: flex;
	 justify-content: center;
	 margin-bottom: 20px;
}
 .model3d .step-controls .system .digits {
	 display: flex;
}
 .model3d .step-controls .step.selected.start-text {
	 color: #ff2d47;
}
 .model3d .step-controls .step {
	 display: flex;
	 justify-content: center;
	 align-items: center;
}
 
 .model3d canvas:focus-visible {
	 border: none !important;
	 outline: none !important;
 }
 
 .model3d .step-controls .step .step-text {
	 height: 25px;
	 width: 25px;
	 background-color: #ff2d47;
	 border-radius: 50%;
	 display: inline-block;
	 color: transparent;
}
 .model3d .step-controls .step .step-text.small {
	 height: 11px;
	 width: 11px;
}
 .model3d .step-controls .step .step-text.rect {
	 height: 3px;
	 width: 22px;
	 border-radius: 0;
}
 .model3d .step-controls .step .step-text:not(.rect):hover {
	 opacity: 0.7;
	 cursor: pointer;
}
 .model3d .step-controls .step:first-of-type::before {
	 visibility: hidden;
}
 .model3d .menu {
	 display: flex;
	 justify-content: space-around;
	 align-items: self-end;
	 width: 100%;
	 margin-bottom: 50px;
}
 .model3d .menu a {
	 text-decoration: none;
	 font-size: large;
}


 .model3d .purpleBtn {
	 text-decoration: none;
	 color: #ff2d47;
	 background-color: transparent;
	 border: 2px solid #ff2d47;
	 padding: 15px 30px;
	 -webkit-border-radius: 25px;
	 -moz-border-radius: 25px;
	 -ms-border-radius: 25px;
	 border-radius: 25px;
	 display: inline-flex;
	 font-family: 'Montserrat', sans-serif;
	 font-weight: 700;
}
 .model3d .purpleBtn:hover {
	 background-color: #ff2d47;
	 color: #fff;
}
 @media only screen and (max-width: 1000px) {
	 .model3d .menu {
		 flex-direction: row;
		 flex-wrap: wrap;
		 justify-content: center;
		 position: relative;
		 bottom: 0;
	}
	 .model3d .menu a {
		 margin: 0 3px 10px 3px;
		 justify-content: center;
		 width: 100px;
		 font-size: small;
	}
	 .model3d .timeline {
		 margin: 0 15px;
	}
}
 