.plan-select div div.react-dropdown-select{
    position: unset;
}

hr.table-line {
    width: 100%;
    height: 1px;
    background-color: #ccc;
    border: none;
    margin: 1em 0;
    opacity: 0.4;
  }
hr.table-line.table-line-first {
    opacity: 1;
    font-weight: bold;
  }
#dental-checkup-parent .Polaris-Connected, #extraction-parent .Polaris-Connected{
  z-index: 0 !important;
}