.titleSmile {
  font-size: 2.5rem;
  font-weight: 600;
  margin-bottom: 1rem;
  color: #ff2d47;
  text-align: center;
  letter-spacing: 0.1rem;
  line-height: 1.5;
  margin-top: 0;
  margin-bottom: 0.5rem;
}
.subtitle {
  font-size: 2rem;
  font-weight: 600;
  color: #ff2d47;
  text-align: center;
  letter-spacing: 0.1rem;
  line-height: 1.5;
  margin-top: 0;
  margin-bottom: 0.5rem;
}

.subtitle .subtitle-inner {
  font-size: 1.5rem;
  color: #333333;
}


.ctaButton {
  background-color: #ff2d47;
  padding: 15px 24px;
  margin: 8px;
  color: white;
  border-radius: 9999px;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 1.25px;
  line-height: 19px;
  box-shadow: 0 4px 10px 0 rgba(135, 167, 171, 0.5);
  display: inline-block;
  width: 230px;

  color: white !important;
  text-decoration: none !important;
  cursor: pointer;
}

/* write code to change button on hover */
.ctaButton:hover {
  background-color: rgba(
    255,
    0,
    0,
    0.3
  ); /* Change the background color on hover */
  box-shadow: 0 4px 10px 0 rgba(135, 167, 171, 0.8); /* Adjust the box shadow on hover */
  /* Add any other styles you want to apply on hover */
}

.yourSmilePlanContainer button {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
}

.ctaButtonPay {
  /* Existing styles */
  margin-bottom: 30px;
  padding: 15px 24px;
  color: #ff2d47;
  border: 2px solid #ff2d47;
  border-radius: 9999px;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 1.25px;
  line-height: 19px;
  box-shadow: 0 4px 10px 0 rgba(135, 167, 171, 0.5);
  display: inline-block;
  width: max-content;
  background-color: white;
  text-decoration: none !important;
}

.ctaButtonPay:hover {
  background-color: rgba(255, 0, 0, 0.05); /* Faint red background on hover */
}

.buttonContainer {
  display: flex;
  justify-content: center;
  margin-top: 30px;
}

.yourSmilePlanContainer {
  font-family: "Eina03-Regular", sans-serif;
}
