.treatment-approach-container {
    display: flex;
    flex-direction: column;
    row-gap: 10px;
}

.treatment-approach-title {
    font-size: 15px !important;
    color: #363636;
}

.info-images-container {
    display: flex !important;
    flex-direction: row !important;
    align-items: stretch;
}

.info-single-image-InputLabel {
    font-size: 14px !important;
}

.info-single-image-container {
    width: 340px;
    height: 303px;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.info-single-image-info {
    position: absolute;
    top: 5px;
    right: 5px;
    cursor: pointer;

    &:hover {
        opacity: 0.8;
    }
}

.info-single-image-img {
    width: 100%;
    height: 100%;
}

.info-single-image-info-content {
    background-color: #fff;
    height: 80%;
    overflow-y: scroll;
    box-shadow: 0 3px 1px -2px rgba(0, 0, 0, .2), 0 2px 2px 0 rgba(0, 0, 0, .14), 0 1px 5px 0 rgba(0, 0, 0, .5);
    border: none;
    border-radius: 15px;
    position: relative;
    padding: 15px 20px;
    color: #36393a;
    font-size: 14px;
}

.info-checkbox-group-img {
    width: 100%;
    height: 210px;
}

.additional-instructions-teeth-container {
    margin: 2rem 0;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media screen and (max-width: 1130px) {
        flex-direction: column;
        gap: 2rem;
    }
}

.additional-instructions-teeth-wrapper {
    width: 49%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.additional-instructions-teeth-svg-div {
    width: 30%;

    @media screen and (max-width: 1300px) {
        width: 50%
    }
}

.additional-instructions-teeth-svg {
    width: 100%;
    height: auto;
}

.additional-instructions-teeth-svg path {
    cursor: pointer;

    &:hover {
        fill: #fea3a3;
    }
}

.additional-instructions-teeth-svg g {
    transform: rotate(0);
}

.additional-instructions-tags-wrapper {
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
    margin-top: 10px;
    justify-content: center;
}

.additional-instructions-tag-container {
    cursor: default;
    display: inline-flex;
    align-items: center;
    transition: box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1);
    padding: 7px;
    border-radius: 16px;
    background-color: #e0e0e0;

    &:hover {
        opacity: 0.8;
    }
}

.additional-instructions-tag {
    color: #36393a;
    font-size: 14px !important;
}

.additional-instructions-tag-close {
    display: flex;
    align-items: center;
    cursor: pointer;
}

.additional-instructions-title-container {
    display: flex;
    column-gap: 5px;
    align-items: center;
}

.additional-instructions-title {
    font-size: 15px !important;
    color: #363636;
}

.additional-instructions-info {
    cursor: pointer;
    font-size: 15px !important;

    &:hover {
        opacity: 0.8;
    }
}

.additional-instructions-teeth-title {
    font-size: 15px;
    color: #363636;
    margin-bottom: 10px;
}

.additional-instructions-teeth-direction-container {
    display: flex;
    justify-content: space-between;
    padding: 0 10px;
}

.additional-instructions-teeth-direction {
    font-size: 18px;
    color: #363636;
}

.info-v2-textfield input {
    font-size: 14px;
}

.info-v2-checkbox .MuiFormControlLabel-label {
    font-size: 13px !important;
}