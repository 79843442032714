.listofjobs {
    display: flex;
    width: 100%;
    align-items: center;
}

.listofjobs-map {
    display: flex;
    width: 100%;
    align-items: center;
}
.copy_img{
    width: 16px;
    height: 16px;
    cursor: pointer;
}
.applicants-title{
    font-size: 16px;
    padding-bottom: 10px;
    font-weight: 500;

}
.applicants-description{
    font-size: 14px;
    font-weight: 500;
    text-transform: capitalize;
}
.col-60{
    width: 60%;
    padding-inline: 40px;
}
.col-40{
    width: 40%;
}
.col-20{
    width: 20%;
    padding-bottom: 21px;
    padding-top: 21px;
}

.col-20 span{
    flex-wrap: wrap;


}
.col-10{
    width: 14%;
    padding-bottom: 21px;
    padding-top: 21px;
}

.col-10 span{
    flex-wrap: wrap;


}
.col-35{
    width: 35%;
    padding-bottom: 21px;
    padding-top: 21px;
}
 
.col-35 label{
    font-weight: 500;
   
}
.col-20 label{
    font-weight: 500;
   
}
.col-10 label{
    font-weight: 500;
   
}
.applicants-details-title{
    font-weight: 500;
    padding-top: 20px;

}
.applicants-details{
    padding-top: 20px;
    display: flex;
    flex-wrap: wrap;

}
.applicants-details div{
  padding-right: 10px;
    
}
.applicants-details span{
    padding-inline: 10px;
}
.applicants-label{
    font-weight: 500;
    padding-bottom: 5px;

}
.close-img{
    width: 15px;
    height: 15px;
}

.close_btn{
    padding-bottom: 10px;
  background-color: transparent;
  border: 0px ;
  cursor: pointer;
}
.view{
    background-color: transparent;
    border: 1px solid #babfc3;
    /* padding-inline: 20px; */
    width: 30px ;
    text-align: center;
    padding-top: 10px;
    padding-bottom: 10px;
    font-weight: 500;
    cursor: pointer;
}
.btn-list-view{
    background-color: transparent;
    border: 1px solid #babfc3;
    padding-inline: 20px;
    text-align: center;
    padding-top: 10px;
    padding-bottom: 10px;
    font-weight: 500;
    cursor: pointer;
}
button{
    cursor: pointer;
}
.btn-cv {
    padding-inline: 8px;
    width: fit-content;
    background-color: transparent !important;
    border: 1px solid #babfc3 !important;
    /* padding-inline: 15px !important; */
    padding-top: 10px!important;
    padding-bottom: 10px!important;
    font-weight: 500!important;
    cursor: pointer;
}

.btnview{
    padding-inline: 6px;
    width: fit-content;
    background-color: transparent !important;
    border: 1px solid #babfc3 !important;
    /* padding-inline: 15px !important; */
    padding-top: 10px!important;
    padding-bottom: 10px!important;
    font-weight: 500!important;
    cursor: pointer;
}
.btnview2{
    padding-inline:22px;
    width: fit-content;
    background-color: transparent !important;
    border: 1px solid #babfc3 !important;
    /* padding-inline: 15px !important; */
    padding-top: 10px!important;
    padding-bottom: 10px!important;
    font-weight: 500!important;
    cursor: pointer;
}
@media (max-width: 575.98px) {
    .col-60{
        width: 100%;
        padding-inline: 0px;
    }
    .col-40{
        width: 100%;
    }
    .career-row-cols-1 {
        flex-wrap: wrap;
        margin-right: -15px;
        margin-left: -15px;
    }

    .career-row-cols-1>* {
        flex: 0 0 100%;
        max-width: 100%;
        padding-right: 15px;
        padding-left: 15px;
    }
}

/* For small devices (tablets) */
@media (min-width: 576px) and (max-width: 767.98px) {
    .career-row-cols-md-2 {
        flex-wrap: wrap;
        margin-right: -15px;
        margin-left: -15px;
    }

    .career-row-cols-md-2>* {
        flex: 0 0 50%;
        max-width: 50%;
        padding-right: 15px;
        padding-left: 15px;
    }
}

@media (min-width: 992px) {
    .career-row-cols-lg-8 {
        flex-wrap: wrap;
        margin-right: -15px;
        margin-left: -15px;
    }

    .career-row-cols-lg-8>* {
        flex: 0 0 12.5%;
        /* 100% / 8 columns */
        max-width: 12.5%;
        /* 100% / 8 columns */
        padding-right: 15px;
        padding-left: 15px;
    }
}

/* For medium devices (desktops) */
@media (min-width: 768px) and (max-width: 991.98px) {
    .career-row-cols-lg-3 {
        flex-wrap: wrap;
        margin-right: -15px;
        margin-left: -15px;
    }

    .career-row-cols-lg-3>* {
        flex: 0 0 33.333333%;
        max-width: 33.333333%;
        padding-right: 15px;
        padding-left: 15px;
    }
}

.row {
    display: flex;
}

/* For large devices (large desktops) and above */
@media (min-width: 992px) {
    .career-row-cols-lg-3 {
        flex-wrap: wrap;
        margin-right: -15px;
        margin-left: -15px;
    }

    .career-row-cols-lg-3>* {
        flex: 0 0 33.333333%;
        max-width: 33.333333%;
        padding-right: 15px;
        padding-left: 15px;
    }
}

.listofjobs-map:hover {
    background-color: rgba(246, 246, 247, 1);
}

form h4 {
    font-size: 20px;
    font-weight: 600;
    padding-bottom: 30px;
}

.listofjobs-map .col20 {
    width: 24%;
    padding-bottom: 21px;
    padding-top: 21px;
    /* padding-inline: 10px; */
    /* padding-bottom: 21px;
    padding-top: 21px;
    border-bottom: 1px solid rgba(225, 227, 229, 1); */
}

.list-jobs-form-group>div {
    width: calc(20% - 10px);
    margin-right: 10px;
}

.listofjobs-map .col10 {
    width: 10%;
    padding-bottom: 21px;
    padding-top: 21px;
    /* padding-bottom: 21px;
    padding-top: 21px;
    border-bottom: 1px solid rgba(225, 227, 229, 1); */
}

.listofjobs-map .col15 {
    width: 15%;
    padding-bottom: 21px;
    padding-top: 21px;
    /* padding-bottom: 21px;
    padding-top: 21px;
    border-bottom: 1px solid rgba(225, 227, 229, 1); */
}
.listofjobs .col20 {
    /* padding-inline: 10px; */

    width: 24%;
    padding-bottom: 21px;
    padding-top: 21px;
    /* padding-bottom: 21px;
    padding-top: 21px;
    border-bottom: 1px solid black; */
}

.listofjobs .col10 {
    width: 10%;
    padding-bottom: 21px;
    padding-top: 21px;
    /* padding-bottom: 21px;
    padding-top: 21px;
    border-bottom: 1px solid black; */
}.listofjobs .col15 {
    width: 15%;
    padding-bottom: 21px;
    padding-top: 21px;
    /* padding-bottom: 21px;
    padding-top: 21px;
    border-bottom: 1px solid black; */
}

.hr-list {
    color: rgba(225, 227, 229, 1) !important;
    margin: 0px;
}

.col20 label {

    font-weight: 500 !important;
}

.col10 label {

    font-weight: 500 !important;
}
.col15 label {

    font-weight: 500 !important;
}
.col20 button {
    background-color: transparent;
    border: 1px solid #babfc3;
    /* padding-inline: 20px; */
    padding-top: 10px;
    padding-bottom: 10px;
    font-weight: 500;
    cursor: pointer;

}

.pagination-jobs {
    display: inline-block;
    margin: 0;
    padding: 0;
    list-style-type: none;
}

.pagination-jobs li {
    display: inline-block;
    margin-inline: 3px;
    cursor: pointer;

}

.selected-page {
    color: black;
    font-weight: 600;
}

.button-pagination {
    background-color: transparent;
    border: 0px solid #babfc3;
    /* padding-inline: 10px;
    padding-top:    5px;
    padding-bottom: 5px; */
    font-weight: 500;
    margin-inline: 5px;
    cursor: pointer;
}

.input_form:focus {
    border: 1px solid #ccc !important;
}

.green-circle {

    background-color: green;
    width: 60px;
    text-align: center;
    padding-top: 5px;
    padding-bottom: 5px;
    border-radius: 5px;

    color: white;
    display: inline-block;
}

.blue-circle {
    width: 60px;
    text-align: center;
    padding-top: 5px;
    padding-bottom: 5px;
    color: white;
    background-color: rgb(60, 118, 235);
    border-radius: 5px;
    display: inline-block;
}

.grey-circle {
    width: 60px;
    text-align: center;
    padding-top: 5px;
    padding-bottom: 5px;
    color: white;
    background-color: grey;
    border-radius: 5px;

    display: inline-block;
}

.col-3 label{
    font-weight: 500;

}
.col-3 div{
 
    padding-top: 10px;
}
.row {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -15px; /* Negative margin to offset padding */
}

.col-3 {
    flex: 0 0 calc(25% - 30px); /* 25% width minus gutters */
    max-width: calc(25% - 30px); /* 25% width minus gutters */
    padding: 0 15px; /* Add horizontal padding */
    box-sizing: border-box;
}

/* Adjust for responsiveness */
@media (max-width: 768px) {
    .col-3 {
        flex: 0 0 calc(50% - 30px); /* 50% width minus gutters */
        max-width: calc(50% - 30px); /* 50% width minus gutters */
    }
}

@media (max-width: 576px) {
    .col-3 {
        flex: 0 0 100%; /* Full width on small screens */
        max-width: 100%;
        margin-bottom: 15px; /* Add margin between columns */
    }
}

/* Example styles for demonstration */
.col-3 {
    margin-top: 20px;

    margin-bottom: 20px; /* Add margin between columns */
}
@media (max-width: 768px) {
    .list-jobs-form-group>div {
        width: calc(50% - 10px);
        margin-right: 10px;
    }

    .col10 {
        padding-inline: 30px;
        width: fit-content;

    }
    .col15 {
        padding-inline: 30px;
        width: fit-content;

    }
    .col20 {
        padding-inline: 100px;
        width: fit-content;

    }

    .listofjobs {
        display: flex;
        /* width: 100%; */
        align-items: end;
        overflow: auto;
    }

    .listofjobs-map {
        display: flex;
        /* width: 100%; */
        align-items: end;
        overflow: auto;
    }
}