
.career-form{
    margin: 0 auto ;
    max-width: 99.8rem;
    background-color: white;
}
.career-form-group {
    display: flex;
    flex-wrap: wrap;
}

.career-form-group > div {
    width: 100%; 
    margin-bottom: 23px; 
}

.career-form-group label {
    display: block; 
    margin-bottom: 7px; 
}

.career-form-group input {
    width: 100%; 
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
}
.career-input_form{
  width: 100%; 
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

.career-form-group input:focus {
    border: 1px solid #ccc;

}
.career-form-group select {
    padding: 10px;
    font-size: 14px;
    border: 1px solid #ccc;
    border-radius: 4px;
    width: 100%;
  }
  .RichTextEditor_job select{
    border: 0px solid #ccc !important;
    margin-inline: 10px !important;
    width: 200px;
    padding: 0px !important;

  }
  .RichTextEditor_job .notranslate.public-DraftEditor-content{
    height: 300px !important;
  }
  .career-form-group select:focus {
    outline: none;
  }
  
  /* Style for select dropdown arrow */
  /* .career-form-group select::after {
    content: '\25BC'; 
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
  }
   */
  /* Style for select dropdown options */
  .career-form-group select option {
   padding-top: 10px;
   padding-bottom: 10px;
  }
  
  /* Style for select dropdown options when hovered */
  /* .career-form-group  select option:hover {
    background-color: #b72525;
  } */

  
  /* Style for textarea when focused */
  .career-form-group textarea:focus {
    outline: none;
  }
  .ql-editor{
    padding-inline: 10px !important;
    padding-bottom: 75px !important;

  }
  
  /* input image  */
  .image-upload {
    position: relative;
    display: inline-block;
    width: 100%;

  }

  .upload-button {
    display: inline-block;
    padding: 7px 20px;
    background-color: transparent;
    border-radius: 5px;
    font-size: 14px;
    cursor: pointer;
    height: 100%;
    border: 2px dashed #ccc; /* Add dashed border */
    width: 100%;
  }
  /* .image-preview{
    position: absolute;
    top: 0;

  } */
  .image-preview img {
    
    max-width: 100%;
    max-height: 100%;
    display: block;
    border-radius: 5px;
    padding-top: 5px;
  }
  .addnewjob{
    background-color: rgb(0, 128, 96);
    border: 0px ;
    color: white;
    font-weight: 500;
    border-radius: 3px;
    padding: 1rem 2rem;
  }
  .custom-quill-editor .ql-editor {
 max-height: 200px;
 min-height: 200px;

 overflow: auto;
}
@media (min-width: 768px) {
    .career-form-group > div {
        width: calc(50% - 10px);
        margin-right: 10px; 
    }
}