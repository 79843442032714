.scrollable-container {
    max-height: 400px; 
    overflow-y: auto; 
}
.question-scrollable-container {
    height: 600px;
    overflow-x: hidden;
    overflow-y: auto;
}
.container-grid {
    display: flex;
    width: 100%;
  }
  .column1 {
    width: 20%;
    margin-right: 10px;

  }
  .column3 {
    width: 40%;
    margin-right: 10px;

  }
  .column {
    width: 14%;
    margin-right: 10px;

 
  }
  .w100{
    width: 100%;
  }
.form-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
}

.form-row label {
    margin-right: 10px;
 
}
.label{
    font-weight: 600;

}


.form-row-question {
    margin-bottom: 10px; 
 
}

.form-row-question label {
    display: block;
    margin-bottom: 7px;
    font-weight: 600;
}
.form-row-question select option {
    padding-top: 10px;
    padding-bottom: 10px;
   }
/* .form-row-question input[type="text"],
select,
input[type="checkbox"] {
    padding: 10px;
    font-size: 14px;
    border: 1px solid #ccc;
    border-radius: 4px;
    width: 100%;
} */

.form-row-question button {
    background-color: rgb(0, 128, 96);
    border: 0px ;
    color: white;
    font-weight: 500;
    border-radius: 3px;
    padding: 1rem 3rem;
}
 .job_input_check[type="checkbox"] {
    /* Hide the default checkbox */
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    width: 18px;
    height: 18px;
    border: 1px solid #ccc;
    border-radius: 4px;
    cursor: pointer;
    outline: none;
    position: relative;
}

 .job_input_check[type="checkbox"]:checked::before {
    content: '\2713'; /* Unicode character for check mark */
    display: block;
    width: 100%;
    height: 100%;
    background-color: #008060; /* Change the background color of the checked checkbox */
    border-radius: 4px;
    color: #fff;
    text-align: center;
    line-height: 18px;
    position: absolute;
    top: 0;
    left: 0;
}
.column30{
    width:30% ;
}
.column20{
    width:20% ;
}
.column15{
    width:10% ;
 
}
.column25{
    width:25% ;
}
@media (max-width: 768px) {
    .form-row-question {
        margin-bottom: 20px; 
        display: flex; 
      
    
    }
    .form-row-question input[type="text"], select, input[type="checkbox"]{
        width: auto !important;
    }
}