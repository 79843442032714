.screening-container {
  max-width: 1000px;
  margin: 0 auto;
  padding: 20px;
  font-family: "Eina03-Regular", sans-serif;
}

.screening-title {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
  color: #ff2d47;
}

.card-section-title {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
}

.form-field {
  margin-bottom: 20px;
}

.form-field strong {
  display: block;
  font-weight: bold;
}

.procedures-list {
  margin-top: 10px;
  margin-left: 20px;
}

.procedures-list-item {
  list-style-type: disc;
}

.text-style {
  color: #333333;
}

.text-style strong {
  font-weight: bold;
  color: #ff2d47;
}
