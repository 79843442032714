.payment-options-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
  font-family: "Eina03-Regular", sans-serif;
}

.payment-option {
  width: 240px;
  height: 350px;
  background-color: white;
  border-radius: 10px;
  padding: 20px;
  text-align: left;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1), 0 8px 16px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.title {
  color: black;
  font-size: 16px;
  font-weight: bold;
  font-family: "Eina03-Bold", sans-serif;
  text-align: left;
}

.sub-title {
  color: black;
  font-size: 16px;
  margin-bottom: 10px;
}

.description {
  margin-top: 20px;
  color: black;
  font-size: 16px;
  text-align: left;
}

.price-container {
  margin-top: 10px;
  margin-bottom: 15px;
}

.payment-options-container > .loading {
  border: 2px solid #f3f3f3;
  border-top: 3px solid #ff2d47;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.price-label {
  color: black;
  font-weight: bold;
  font-size: 16px;
  font-family: "Eina03-SemiBold", sans-serif;
}

.price {
  color: #ff2d47;
  font-size: 20px;
  font-weight: bold;
  font-family: "Eina03-Bold", sans-serif;
}

.purchase-button {
  background-color: #ff2d47;
  color: white;
  border: none;
  border-radius: 9999px;
  padding: 15px 24px;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 1.25px;
  line-height: 19px;
  box-shadow: 0 4px 10px 0 rgba(135, 167, 171, 0.5);
  cursor: pointer;
}

.purchase-button:hover {
  background-color: #ffcccc;
}

.plans-hr {
  border: 4px solid #ff2d47;
  margin: 20px 100px 0 0;
  border-radius: 30px;
}

.totalPrice {
  font-weight: bold;
  margin-top: 20px;
}

.monthly-pay {
  color: black;
  font-weight: 100;
  font-size: 16px;
}

.dropdown {
  margin-top: 10px;
}
