.case-record-files-wrapper{
    display: flex;
    column-gap: 2%;
}

.case-record-files{
    flex: 1;
}

.undo-action-container {
    position: fixed;
    bottom: 10px;
    display: flex;
    border-radius: 20px;
    column-gap: 10px;
    color: #fff;
    background-color: #000;
}

.undo-action-title {
    padding: 7px 10px;
    border-right: 1px solid #fff;
}

.undo-action-icon-container {
    display: flex;
    align-items: center;
    column-gap: 5px;
    cursor: pointer;
    padding: 7px 10px 7px 0;

    &:hover{
        opacity: 0.8;
    }
}

.undo-action {
    font-weight: bold;
}

.refresh-component-container {
    z-index: 20;
    position: fixed;
    bottom: 10px;
    display: flex;
    border-radius: 20px;
    column-gap: 3px;
    align-items: center;
    color: #fff;
    background-color: #000;
    padding: 10px;
}

.refresh-component-refresh-btn {
    cursor: pointer;
    text-decoration: underline;

    &:hover{
        opacity: 0.8;
    }
}

.case-record-files-container{
    position: relative;
    background-color: #fff;
    padding: 1rem 0;
    box-shadow: 0 0 0 1px rgba(63,63,68,0.05), 0 1px 3px 0 rgba(63,63,68,0.15);
}

.procedure-header-title{
    font-weight: bold;
}

.header-title-gray{
    color: #D3D3D3;
}

.case-record-appointment, .case-record-appointment-info{
    margin-bottom: 20px;
}

.case-record-appointment-completed{
    font-style: italic;
}

.case-record-step-wrapper{
    display: flex;
    height: 60px;
}

.case-record-step{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-right: 10px;
}

.case-record-line, .case-record-line-end{
    border: 1px solid;
    flex-grow: 1;
}

.case-record-line-end{
    border: 1px solid transparent;
}

.case-record-circle, .case-record-circle-active{
    cursor: pointer;
    border: 1px solid;
    border-radius: 100%;
    padding: 3px;
}

.case-record-circle-active{
    background-color: black;
}

.case-record-step-content-wrapper{
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.date-time-picker{
    width: 100% !important;
}

.date-time-picker div{
    display: flex;
    justify-content: space-between;
}

.date-time-picker div label{
    flex: 1;
}

.date-time-picker div label span{
    display: none;
}

.date-time-picker div label select{
    height: 38px;
    width: 100%;
    text-align: center;
    border: none;
    opacity: 0.52;
    background-color:rgba(211,215,219,0.25);
    color: #303030;
}

.date-time-picker div label select:focus{
   outline: none;
}

.info .MuiOutlinedInput-root .MuiSelect-select{
    font-size: 12px !important;
    min-height: unset !important;
}