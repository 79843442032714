.create-ticket-card{
    margin: auto;
    width: 80%;
    padding: 20px;
}
.create-tikcet-filter-body{
    display: flex;
    gap: 20px;
    flex-direction: row;
    flex-wrap: wrap; height: 100%;
    justify-content: space-between;
    align-content: space-around;
}
.create-ticket-media-parent{
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    overflow-y: auto;
    max-height: 100px;
    overflow-x: hidden;
}
.create-ticket-media{
    position: relative;
    width: fit-content;
    padding-right: 3%;
    padding-left: 1%;
    border: 1px solid #b0adad;
    border-radius: 5px;
    background-color: #9895954f;
}