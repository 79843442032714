.ik-bas-files-wrapper {
    padding: 0 2rem;
    margin-bottom: 2rem;
}

.ik-bas-wrapper, .screening-wrapper {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
}

.ik-bas-s3-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 10px;
    cursor: pointer;
}

.ik-bas-txt {
    text-align: center;
}

.info-btn-wrapper {
    padding: 0 2rem;
}

.questions-img-wrapper {
    display: flex;
    flex-direction: column;
    width: fit-content;
    row-gap: 10px;
}

.questions-img-wrapper .Polaris-Thumbnail--sizeLarge {
    width: 12rem;
}

.questions-img-wrapper .Polaris-DropZone--sizeMedium {
    min-height: fit-content;
}