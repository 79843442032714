.tooth-checkbox-container {
  display: flex;
  align-items: center;
  margin: 7% 0;
  flex-direction: column;

}

.tooth {
  width: 30px;
  height: 30px;
  background-color: #fff;
  border: 2px solid #000;
  border-radius: 50% 50% 50% 50% / 0% 0% 100% 100%;
  transform: rotate(-45deg);
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.tooth.checked {
  background-color: #000;
}

.single-tooth {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.teeth-upper .single-tooth {
  justify-content: flex-end;
}

.red-square-upper-sheet {
  background-color: #ff2c46;
  width: 7px;
  height: 7px;
  display: block;
  position: absolute;
  z-Index: 1;
  right: 45%;
  top: 60%;
  border-radius: 5px;
}

.red-square-upper-sheet-bottom {
  background-color: #ff2c46;
  width: 7px;
  height: 7px;
  display: block;
  position: absolute;
  z-Index: 1;
  right: 45%;
  bottom: 65%;
  border-radius: 5px;
}

.teeth-upper,
.teeth-bottom {
  display: flex;
  width: 90%;
  margin: auto;
}

.container {
  text-align: center;
}