.ticket-details-main,.ticket-details-comment{
    width:65%;
    flex-grow:1;
}
.ticket-details-parent{
    display:flex;
    height:40vh;
}
.ticket-details-general{
    width:70%;
    padding:16px;
    border-right:1px solid #efe9e9;
    display: flex;
    flex-direction: column;
}
.ticket-details-info{
    width:30%;
    padding:16px;
}

.ticket-details-media,.ticket-details-workflow{
    width:30%;
}

@media screen and (max-width: 1468px){
    .ticket-details-parent{
        height:60vh;
    }
    .ticket-details-general{
        width:65%;
    }
    .ticket-details-info{
        width:35%;
    }
    .ticket-details-media,.ticket-details-workflow{
        width:25%;
    }
}