.parent-container{
    display: flex;
    gap: 5%;
}
.order-container{
    width: 65%;
}
.user-container{
    width: 30%
}
.order-item-container{
    display: flex;
    align-items: center;
    gap: 10px;
}
.order-item-info{
    flex: 1.5;
}
.order-item-price-info{
    display: flex;
    gap: 5px;
    flex:1;
}
/* .order-info-container{
    display: flex;
} */
.order-info{
    display: flex;
    height: 50px;
}
.order-info-s{
    width: 20%;
}
.order-info-se{
    width: 20%;
    text-align: end;
}
.order-info-l{
    flex: 1;
    text-align: center;
}