.MuiOutlinedInput-notchedOutline {
  border-width: 2px !important;
}

.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: #ff2d47 !important;
}

.MuiInputLabel-root.Mui-focused {
  color: black !important;
}

.MuiMenuItem-root {
  font-family: "Eina03-SemiBold", sans-serif !important;
  font-size: 10px !important;
}

.MuiMenuItem-root.Mui-selected {
  background-color: rgba(255, 45, 71, 0.5) !important;
}

.buttonsContainer {
  display: flex;
  justify-content: center;
}
