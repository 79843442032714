.itemRL {
  margin-left: 20px;
  min-width: 200px;
  /* max-width: 0px; */
}

.divItemName {
  display: inline-block;
  min-width: 300px;
  max-width: 300px;
  align-items: middle;
}
.divItemID {
  display: inline-block;
  min-width: 80px;
  align-items: middle;
}
.divItemStatus {
  float: right;
  align-items: middle;
  /* min-width: 150px; */
}
.itemLabel {
  display: inline-block;
  min-width: 150px;
  align-items: middle;
  margin-left: 20px;
}
.div1 {
  margin: 0 auto;
}

.clinic-status span{
  background-color: #ff2c4c !important;
  color: #ffffff;
}
