


.treatment-actions {
  padding: 8px;
  background-color: var(--type-color, black);
  color:white;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-size: initial;
}
@media screen and (max-width:1400px) {
  .treatment-actions{
    font-size: smaller;
  }
}

/* .treatment-actions[type] {
  --type-color: attr(type);
} */

.treatment-actions[type="confirm"] {
  --type-color: #0CA789;
}

.treatment-actions[type="reject"] {
  --type-color: #F24726;
}

.treatment-actions[type="modify"] {
  --type-color: #F7A35C;
}